.splash-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .container {
        max-width: 1200px;
        margin: auto;
        display: flex;
        align-items: center;
        gap: 50px;

        .splash-img {
            width: 50%;

            .img {
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 500px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }

        .splash-content {
            flex: 1;
            display: grid;
            height: fit-content;
            gap: 24px;
        }
    }
}

@media screen and (max-width: 720px) {
    .splash-screen {
        .container {
            gap: 24px;
            flex-direction: column;
            padding: 24px;

            .splash-img {
                width: 100%;

                .img {
                    &::after {
                        height: 200px;
                    }
                }
            }

            .splash-content {
                text-align: center;

                .button {
                    width: 100%;
                }
            }
        }
    }
}

// Theming

[data-theme="light"] {
    .splash-img {
        .img {
            &::after {
                background: url('../../../img/pages/splashScreen/splash-image-light.png');
            }
        }
    }
}

[data-theme="dark"] {
    .splash-img {
        .img {
            &::after {
                background: url('../../../img/pages/splashScreen/splash-image-dark.png');
            }
        }
    }
}