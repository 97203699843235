input,
select,
.search-input,
textarea {
  padding: 16px 18px;
  font-size: inherit;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  font-size: 16px;
  color: rgb(57, 57, 57);
  caret-color: #f87c47;
  outline: none;
}
input::-moz-placeholder, select::-moz-placeholder, .search-input::-moz-placeholder, textarea::-moz-placeholder {
  color: #808080;
}
input::placeholder,
select::placeholder,
.search-input::placeholder,
textarea::placeholder {
  color: #808080;
}

select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

textarea {
  caret-color: #f87c47;
}

.date-input {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 16px 18px;
}
.date-input input {
  border: 0;
  padding: 0;
  width: 2rem;
  text-align: center;
}
.date-input .yyy {
  width: 4rem;
}

textarea:focus {
  box-shadow: 0 0 0 1pt #f87c47;
}

input[type=text]:focus,
input[type=time]:focus,
input[type=date]:focus,
input[type=number]:focus {
  box-shadow: 0 0 0 1pt #f87c47;
}

.custom-time-input:focus-within {
  box-shadow: 0 0 0 1pt #f87c47;
}

.custom-time-input input[type=number] {
  /* Optionally, remove box shadow from the input if it has default styles */
  box-shadow: none;
}

.routes {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
.routes p {
  flex: 1;
}

.check-box {
  cursor: pointer;
}

.other {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.other button {
  width: -moz-fit-content;
  width: fit-content;
}

.custom-input {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #f1f1f1;
  padding: 0 18px;
  border-radius: 10px;
}
.custom-input input {
  flex: 1;
  border: none;
  padding: 16px 0;
}
.custom-input .icon {
  color: #808080;
}

.custom-time-input {
  justify-content: space-between;
  position: relative;
}
.custom-time-input .inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-time-input .time-hour-drop-down {
  position: absolute;
  right: 0;
  top: 3rem;
  background-color: white;
  padding: 0.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
  border: 1px solid gray;
  border-radius: 0.5rem;
}
.custom-time-input .time-hour-drop-down .drop-downs {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.custom-time-input .time-hour-drop-down .drop-downs .minutes,
.custom-time-input .time-hour-drop-down .drop-downs .hours {
  display: grid;
  max-height: 100px;
  gap: 6px;
  overflow-y: auto;
}
.custom-time-input .time-hour-drop-down .drop-downs .time {
  background-color: red;
}
.custom-time-input .time-hour-drop-down .drop-downs .hour {
  background-color: blue;
}
.custom-time-input .time-hour-drop-down .drop-downs .unit {
  padding: 0.4rem 1rem;
}

.forms-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: start;
}
.forms-container .form-steps {
  display: flex;
  align-items: start;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #f1f1f1;
  padding: 14px 12px;
  border-radius: 12px;
}
.forms-container .form-steps .step {
  display: flex;
  align-items: start;
  gap: 6px;
}
.forms-container .form-steps .step .icon {
  color: gray;
}
.forms-container .form-steps .step .icon i {
  font-size: 22px;
}
.forms-container .form-steps .step .step-details {
  display: none;
}
.forms-container .form-steps .current-step .step-details {
  display: block;
}
.forms-container .form-steps .current-step .icon {
  color: #F87C47;
}
.forms-container .newIncidentForm .radio {
  display: flex;
}
.forms-container .form-buttons {
  height: 48px;
  flex: 1;
  background-color: white;
}

.field {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}

.checkings {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.straight-checkings {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.addition-button {
  margin-top: 10px;
}

.type1 {
  cursor: pointer;
  display: flex;
  gap: 6px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 6px;
  cursor: pointer;
}
.type1 input[type=checkbox] {
  display: none;
}
.type1 input[type=checkbox]:checked + label {
  background-color: #f87c47;
  color: white;
}
.type1 label {
  cursor: pointer;
  display: block;
  padding: 12px 26px;
  border-radius: 5px;
  background-color: #f3f3f3;
}
.type1 label:hover {
  background-color: #f87c47;
  color: white;
}

.varience-duration .half {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
}
.varience-duration .half input {
  width: 50%;
  display: block;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
form .incident-info,
form .other-info,
form .incident-type,
form .location-status {
  display: grid;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
}
form .one {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
}
form .one .address {
  border: none;
  gap: 8px;
  padding: 0;
}
form .one .state {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
form .one .zipCode {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
form .one .phoneNumber {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
form input {
  width: 100%;
  border: 1px solid transparent;
}
form select {
  width: 100%;
}
form .checkbox {
  display: flex;
  gap: 12px;
}
form .types {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: 10px;
}
form .types .type {
  display: flex;
  gap: 6px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 26px;
  border-radius: 6px;
  cursor: pointer;
}
form .types .type input {
  width: unset;
}
form .types .full-width-type {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}
form .half {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
form .half .sex {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
form .check-boxes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
form .other-info .check-boxes {
  gap: 24px;
}
form .check-box {
  display: flex;
  align-items: center;
  gap: 6px;
  width: -moz-fit-content;
  width: fit-content;
}
form .check-box label {
  text-wrap: nowrap;
}
form .fall-related {
  display: grid;
  gap: 12px;
}
form .full-address {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

@media screen and (max-width: 760px) {
  form .half {
    grid-template-columns: 1fr;
  }
  .forms-container .half {
    grid-template-columns: 1fr;
  }
  .forms-container .half div {
    width: 100%;
  }
  .forms-container .half .incident-date {
    width: 100%;
  }
  .forms-container .form-steps .step {
    display: none;
  }
  .forms-container .form-steps .current-step {
    display: flex;
  }
  .forms-container .form-steps .divider {
    display: none;
  }
}
.step {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.step .sub-h1 {
  font-size: 18px;
}
.step .check-boxes-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.parties {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.parties .new-party {
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgba(20, 91, 158, 0.1490196078);
  color: #145C9E;
}
.parties .new-party .icon {
  height: 20px;
  width: 20px;
  border: 1px solid #145C9E;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px;
  border-radius: 12px;
  border: 1px dashed gray;
}
.upload-field .icon {
  width: 50px;
  height: 40px;
  border-radius: 6px;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-field .upload-area-content {
  display: grid;
  gap: 2px;
}
.upload-field .upload-area-content .upload-text {
  font-size: 16px;
  color: #145C9E;
}
.upload-field .upload-area-content .size-text {
  font-size: 10px;
}

.form-notification {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  padding: 48px;
}
.form-notification .info-icon-main-container {
  padding: 20px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50%;
}
.form-notification .info-icon-main-container .info-container {
  padding: 20px;
  border-radius: 50%;
}
.form-notification .info-icon-main-container .info-container .info-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-notification .info-icon-main-container .info-container .info-icon i {
  font-size: 18px;
}
.form-notification p {
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  max-width: 300px;
}

.step-2-status > select {
  font-size: 14px;
  font-weight: 500;
}

textarea {
  resize: none;
  white-space: pre-wrap;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  width: 100%;
}

.description-of-varience {
  height: 100%;
}
.description-of-varience h2 {
  font-size: 18px;
}
.description-of-varience h2 span {
  font-weight: 400;
}
.description-of-varience .types {
  max-height: 500px;
  overflow-y: auto;
}
.description-of-varience .types .type {
  width: 100%;
}

.medication-step-4 {
  width: 100%;
}
.medication-step-4 .form-of-error {
  margin-bottom: 100px;
}
.medication-step-4 .check-boxes-row {
  width: 100%;
}
.medication-step-4 .check-boxes-row .col {
  gap: 20px;
}
.medication-step-4 .check-boxes-row label {
  text-wrap: wrap;
}

[data-theme=light] form .types .type {
  background-color: #f3f3f3;
}
[data-theme=light] form .types .selected {
  background-color: #F87C47;
  color: white;
}
[data-theme=light] form input {
  border-color: #f3f3f3;
}
[data-theme=light] .form-notification {
  background-color: #f3f3f3;
}
[data-theme=light] .form-notification .info-icon-main-container {
  background-color: #d9e8f6;
}
[data-theme=light] .form-notification .info-icon-main-container .info-container {
  background-color: #145C9E;
}
[data-theme=light] .form-notification .info-icon-main-container .info-container .info-icon {
  border: 2px solid white;
}
[data-theme=light] .form-notification .info-icon-main-container .info-container .info-icon i {
  font-size: 18px;
  color: white;
}
[data-theme=light] .form-notification p {
  color: #3c3c3c;
}
[data-theme=light] .step-2-status > select {
  border-color: #f3f3f3;
  color: #2C2C2C;
}

[data-theme=dark] form .types .type {
  background-color: #1E1E1E;
}
[data-theme=dark] form .types .selected {
  background-color: #F87C47;
  color: white;
}
[data-theme=dark] form .type1 label {
  background-color: #1E1E1E;
}
[data-theme=dark] form .type1 label:hover {
  background-color: #f87c47;
  color: white;
}
[data-theme=dark] form .type1 label input[type=checkbox]:checked + label {
  background-color: #f87c47;
  color: white;
}
[data-theme=dark] form input {
  border: 1px solid transparent;
  border-color: rgb(123, 123, 123);
}
[data-theme=dark] .form-notification {
  background-color: #1E1E1E;
}
[data-theme=dark] .form-notification .info-icon-main-container .info-container {
  background-color: #2C2C2C;
}
[data-theme=dark] .form-notification .info-icon-main-container .info-container .info-icon {
  border: 1px solid orange;
}
[data-theme=dark] .form-notification .info-icon-main-container .info-container .info-icon i {
  font-size: 18px;
  color: orange;
}
[data-theme=dark] .form-notification p {
  color: white;
}

.parties-involved-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid whitesmoke;
}
.parties-involved-list .party {
  padding: 6px 12px;
  background-color: whitesmoke;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
}

.full-width input {
  width: 100%;
}

#custom-select-modified-input {
  width: 250px;
}
#custom-select-modified-input .modified-input {
  position: relative;
  display: flex;
  align-items: center;
}
#custom-select-modified-input .modified-input input {
  padding: 12px;
  font-size: 13px;
  color: gray;
}
#custom-select-modified-input .modified-input .angle-down {
  position: absolute;
  right: 10px;
  transition: 0.5s ease;
  z-index: 8;
}
#custom-select-modified-input .modified-input .rotate-angle {
  transform: rotate(180deg);
  transition: 0.5s ease;
}
#custom-select-modified-input .options #option {
  font-size: 12px;
  display: flex !important;
  flex-direction: row;
  gap: 20px;
}
#custom-select-modified-input .options #option:hover {
  background-color: rgba(248, 124, 71, 0.315) !important;
}
#custom-select-modified-input .options #option .option-title {
  color: red;
}

.custom-select-input {
  position: relative;
}
.custom-select-input .options-container {
  background-color: whitesmoke;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 4;
}
.custom-select-input .options {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
}
.custom-select-input .options .option {
  padding: 6px;
  border-bottom: 1px solid rgb(231, 231, 231);
}
.custom-select-input .options .option:last-child {
  border-bottom: none;
}
.custom-select-input .options .option:hover {
  background-color: #F87C47;
  border-color: transparent;
}
.custom-select-input .options .option:first-child {
  color: gray;
}
.custom-select-input .options .option:first-child:hover {
  background-color: transparent;
}

.checkbox-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
}
.checkbox-grid > div {
  height: 100% !important;
}

.full-height {
  height: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: nowrap;
}

.margin10 {
  margin: 10px;
}

.width-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.forms-header {
  position: sticky;
  top: 0;
  bottom: 0;
  padding: 1.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  background-color: white;
  z-index: 100;
}

.newIncidentForm {
  padding: 0 1.5rem;
}

.forms-container .buttons {
  padding: 1.5rem;
}

.suggestions-field {
  position: relative;
}
.suggestions-field input {
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: none !important;
}
.suggestions-field .suggestions {
  position: absolute;
  background-color: white;
  padding: 12px;
  width: 100%;
  left: 0;
  top: 5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 0 0 1pt #f87c47;
  z-index: 100;
}
.suggestions-field .suggestions .suggestions-container,
.suggestions-field .suggestions .list {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  width: 100%;
}
.suggestions-field .suggestions .list {
  max-height: 300px;
  height: auto;
  overflow: auto;
  gap: 0.5rem;
}
.suggestions-field .suggestions .more-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.suggestions-field .suggestions .suggestion {
  background-color: whitesmoke;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.2rem;
  padding: 1rem;
  border-radius: 0.5rem;
}
.suggestions-field .suggestions small {
  color: #808080;
}

.forms-header .warning-message {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.forms-header .warning-message .row {
  display: flex;
  gap: 20px;
  align-items: center;
}
.forms-header .warning-message .row .orange-color {
  color: #F87C47;
}
.forms-header .warning-message .row .icon-container {
  background-color: #fffadd;
  border-radius: 8px;
  border: 1px solid rgba(248, 124, 71, 0.2039215686);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forms-header .warning-message .row .col {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.forms-header .warning-message .row .col h4 {
  font-size: 14px;
}
.forms-header .warning-message .row .col p {
  font-size: 12px;
  color: #575757;
}
.forms-header .warning-message .actions-buttons {
  display: flex;
  gap: 12px;
}
.forms-header .warning-message .actions-buttons .action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 6px 15px;
  gap: 10px;
  background-color: #fffadd;
  color: #F87C47;
}
.forms-header .warning-message .actions-buttons .action-button .icon {
  height: 18px;
  width: 18px;
  font-weight: 700;
}
.forms-header .warning-message .actions-buttons .action-button span {
  font-size: 12px;
  font-weight: 700;
}
.forms-header .warning-message .cancel-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  color: #575757;
}

.new-department {
  padding: 2rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  position: relative;
}

.close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}/*# sourceMappingURL=forms.css.map */