.restPassword {
  display: flex;
}
.restPassword .img {
  width: 50%;
  height: 100%;
}
.restPassword .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.restPassword .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.restPassword .container p {
  font-style: poppins;
}
.restPassword .container form {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.restPassword .container form .title {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.restPassword .container form a {
  width: 100%;
  padding: 20px 0px;
  background-color: #F87C47;
  color: #FFFFFF;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}
.restPassword .container form a:hover {
  border: 2px solid #F87C47;
  color: #F87C47;
  background-color: #FFFFFF;
}
.restPassword .container form button {
  width: 100%;
  padding: 20px 30px;
  border: 2px solid #F87C47;
  color: #F87C47;
  background-color: #FFFFFF;
  border-radius: 30px;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}
.restPassword .container form button:hover {
  border: 2px solid #F87C47;
  color: #F87C47;
  background-color: #FFFFFF;
}/*# sourceMappingURL=restpass.css.map */