.facilities-list,
.departments-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
.facilities-list .facility-item,
.facilities-list .department-item,
.departments-list .facility-item,
.departments-list .department-item {
  border: 1px solid #ddd;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: #e8f2fc;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.facilities-list .facility-item .facility-title,
.facilities-list .department-item .facility-title,
.departments-list .facility-item .facility-title,
.departments-list .department-item .facility-title {
  color: #145C9E;
}
.facilities-list .facility-item .icon,
.facilities-list .department-item .icon,
.departments-list .facility-item .icon,
.departments-list .department-item .icon {
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #145C9E;
  background-color: #bbdaf6;
}
.facilities-list .facility-item small,
.facilities-list .department-item small,
.departments-list .facility-item small,
.departments-list .department-item small {
  color: gray;
}
.facilities-list > :nth-child(even),
.departments-list > :nth-child(even) {
  background-color: #feece4;
}
.facilities-list > :nth-child(even) .icon,
.departments-list > :nth-child(even) .icon {
  background-color: #fdd3c2;
  color: #F87C47;
}

.tabs-list {
  display: flex;
  border: 1px solid whitesmoke;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: -moz-fit-content;
  width: fit-content;
}
.tabs-list .tab {
  cursor: pointer;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgb(96, 96, 96);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-wrap: nowrap;
}
.tabs-list .active {
  background-color: #fee5da;
  color: #F87C47;
}

.reports-list .reports-categories {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}

.reports-card .no-data-found {
  border-radius: 0px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.reports-card .results-table {
  gap: 0;
}
.reports-card .filters_popup h3 {
  color: black;
}

.reports-card,
.content-card {
  border: 1px solid #8dc1f1;
  border-radius: 0.5rem;
}
.reports-card .status,
.content-card .status {
  padding: 1rem;
}
.reports-card .update-follow-up,
.content-card .update-follow-up {
  max-width: 100px;
  width: 100%;
  padding-inline: 1.5rem;
  display: inline-block;
}
.reports-card .card-header,
.content-card .card-header {
  background-color: #145C9E;
  padding: 1rem;
  padding-block: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: white;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.reports-card .card-header .option,
.content-card .card-header .option {
  color: #909090;
}
.reports-card .card-header .option-hover:hover,
.content-card .card-header .option-hover:hover {
  color: white;
  cursor: pointer;
}
.reports-card .card-header .option-header,
.content-card .card-header .option-header {
  color: black !important;
}
.reports-card .card-header .date-filter-button,
.content-card .card-header .date-filter-button {
  border: 1px solid white;
  cursor: pointer;
  padding: 10px;
  padding-block: 5px;
}
.reports-card .card-header .filter-range span,
.content-card .card-header .filter-range span {
  color: #909090;
}
.reports-card .card-header .system-search-container,
.content-card .card-header .system-search-container {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
}
.reports-card .card-header .system-search-container .search-icon,
.content-card .card-header .system-search-container .search-icon {
  position: absolute;
  margin-left: 20px;
  color: rgb(224, 224, 224);
}
.reports-card .card-header .system-search-container #systemSearch,
.content-card .card-header .system-search-container #systemSearch {
  width: 100%;
  background-color: #4678a7;
  padding-left: 50px;
  border: none;
  color: rgb(224, 224, 224);
  padding-block: 10px;
}
.reports-card .card-header .system-search-container #systemSearch::-moz-placeholder, .content-card .card-header .system-search-container #systemSearch::-moz-placeholder {
  color: rgb(224, 224, 224);
}
.reports-card .card-header .system-search-container #systemSearch::placeholder,
.content-card .card-header .system-search-container #systemSearch::placeholder {
  color: rgb(224, 224, 224);
}

.department-lists-container {
  display: grid;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
}
.department-lists-container button {
  width: -moz-fit-content;
  width: fit-content;
}

.department-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.department-details-header .header-items {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.department-details-header .header-items .name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.department-details-header .header-items .name .icon {
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F87C47;
  background-color: #fee5da;
}
.department-details-header .header-items .numbers {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.department-details-header .header-items .numbers .number {
  max-width: 160px;
  background-color: whitesmoke;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
}
.department-details-header .header-items .numbers .number small {
  text-wrap: nowrap;
}

@media screen and (max-width: 960px) {
  .facilities-list {
    grid-template-columns: 1fr 1fr;
  }
  .tabs-list {
    overflow: auto;
    max-width: 83vw;
  }
  .department-details-header {
    align-items: baseline;
  }
  .department-details-header .header-items {
    flex-direction: column;
    align-items: start;
  }
}
@media screen and (max-width: 540px) {
  .facilities-list {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=facilities.css.map */