.forms-container .img-text {
  background-color: #F7F7F7;
  border-radius: 12px;
  padding: 24px;
  width: 500px;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.forms-container .img-text h2 {
  font-weight: bolder;
}
.forms-container .img-text p {
  color: #909090;
  font-size: 16px;
  text-align: center;
}
.forms-container .img-text img {
  width: -moz-fit-content;
  width: fit-content;
}/*# sourceMappingURL=grivance.css.map */