.login-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}
.login-page .branding .logo {
  max-width: 80px;
}
.login-page .login-form,
.login-page .login-img {
  flex: 1 1 400px;
}
.login-page .login-img {
  width: 100%;
  max-width: 600px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
}
.login-page .login-form {
  max-width: 600px;
  padding: 48px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.login-page .login-form .entities {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.login-page .login-form .entities .entity {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-basis: 150px;
  height: 92px;
  font-weight: bold;
  border-radius: 6px;
}
.login-page .login-email-password {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.login-page .login-email-password form {
  padding: 0;
}
.login-page .login-email-password form .input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}
.login-page .login-email-password form .input-with-icon .icon {
  color: rgb(183, 183, 183);
  font-size: 20px;
  position: absolute;
  left: 1rem;
}
.login-page .login-email-password form .input-with-icon input {
  border-radius: 8px;
  border: 1px solid rgb(183, 183, 183);
  color: black;
  font-size: 16px;
  padding: 12px 24px;
  padding-left: 3rem;
}
.login-page .login-email-password form .remember-forget {
  display: flex;
  justify-content: space-between;
}
.login-page .login-email-password form .remember-forget label {
  color: black;
}
.login-page .login-email-password form .remember-forget a {
  text-decoration: none;
  color: rgb(248, 124, 71);
  font-size: 16px;
}
.login-page .login-email-password form .remember-forget .remember {
  display: flex;
  gap: 4px;
}
.login-page .login-email-password form .remember-forget .remember input {
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgb(248, 124, 71);
}
.login-page .login-email-password form button {
  padding: 12px 24px;
}

.remember-forget {
  cursor: pointer;
}

@media screen and (max-width: 860px) {
  .login-page .login-img {
    display: none;
  }
  .login-page .login-form {
    width: 100%;
    margin: auto;
    padding: 24px;
  }
  .login-page .login-form .entities .entity {
    flex-basis: 120px;
  }
}
[data-theme=light] .login-page .login-form .entities .entity {
  background-color: whitesmoke;
  color: #145C9E;
}
[data-theme=light] .login-page .login-form .entities .selected {
  border: 1px solid #F87C47;
  color: #F87C47;
}

[data-theme=dark] .login-page .login-form .entities .entity {
  background-color: #2C2C2C;
  color: white;
}
[data-theme=dark] .login-page .login-form .entities .selected {
  border: 1px solid white;
  color: white;
}/*# sourceMappingURL=login.css.map */