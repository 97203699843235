.report,
.property_found,
.outcomes {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px;
  border-bottom: 1px solid whitesmoke;
}
.report .reporting_relationship,
.property_found .reporting_relationship,
.outcomes .reporting_relationship {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
}
.report .reporting_relationship .reporting, .report .reporting_relationship .relationship,
.property_found .reporting_relationship .reporting,
.property_found .reporting_relationship .relationship,
.outcomes .reporting_relationship .reporting,
.outcomes .reporting_relationship .relationship {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.report .person_report,
.property_found .person_report,
.outcomes .person_report {
  display: flex;
  flex-direction: column;
}
.report > div,
.property_found > div,
.outcomes > div {
  display: grid;
  gap: 8px;
  height: -moz-fit-content;
  height: fit-content;
}

.property_found .returned {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
}
.property_found .returned .property_returned,
.property_found .returned .date_time_returned {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.property_found .found {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
}
.property_found .found .location,
.property_found .found .date_time_found {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.property_found .property_found_by {
  display: flex;
  flex-direction: column;
}/*# sourceMappingURL=lost_found_details.css.map */