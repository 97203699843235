.preference-popup-content {
  position: relative;
  display: grid;
  height: fit-content;
  padding: 2rem;
  background-color: white;
  width: 100%;
  max-width: 600px;
  border-radius: 0.5rem;

  .preferences-form {
    display: grid;
    gap: 1rem;
  }
}

// SearchableSelect.scss
.searchable-select {
  position: relative;
  width: 100%;

  .select-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border-color: #cbd5e1;
    }

    &.open {
      border-color: #3b82f6;
      box-shadow: 0 0 0 1px #3b82f6;
    }

    &.error {
      border-color: #ef4444;
    }

    .selected-value {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .trigger-icon {
      width: 20px;
      height: 20px;
      margin-left: 0.5rem;
      color: #64748b;
      transition: transform 0.2s ease;
    }

    &.open .trigger-icon {
      transform: rotate(180deg);
    }
  }

  .select-dropdown {
    position: absolute;
    top: calc(100% + 0.25rem);
    left: 0;
    right: 0;
    z-index: 50;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);

    .search-container {
      position: relative;
      padding: 0.5rem;
      border-bottom: 1px solid #e2e8f0;

      .search-icon {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        color: #64748b;
      }

      input {
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 2.5rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.375rem;
        outline: none;
        transition: all 0.2s ease;

        &:focus {
          border-color: #3b82f6;
          box-shadow: 0 0 0 1px #3b82f6;
        }

        &::placeholder {
          color: #94a3b8;
        }
      }
    }

    .options-container {
      max-height: 15rem;
      overflow-y: auto;

      .option {
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #f8fafc;
        }

        &.selected {
          background-color: #eff6ff;
          color: #3b82f6;
        }
      }

      .no-options {
        padding: 0.75rem;
        text-align: center;
        color: #64748b;
      }
    }
  }

  .error-message {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #ef4444;
  }
}
