@import "../../variables.scss";

.error-page {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    max-width: 600px;
    margin: auto;

    display: grid;
    gap: 24px;
    height: fit-content;

    .branding {
      display: flex;
      align-items: center;
      gap: 18px;

      .logo {
        height: 40px;
      }

      .site-title {
        font-size: 22px;
      }
    }

    .content {
      display: grid;
      height: fit-content;
      gap: 24px;

      .error-title {
        display: flex;
        align-items: center;
        gap: 12px;

        .error-code {
          color: $secondary-color;
          font-size: 32px;
          font-weight: bold;
        }

        .divider {
          width: 5px;

          height: 50px;
        }

        .title {
          font-size: 52px;
        }
      }

      .error-details {
        padding: 24px;
        border-radius: 12px;
        display: grid;
        height: fit-content;
        gap: 24px;

        .useful-links {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;

          .useful-link {
            padding: 6px 18px;
            border-radius: 12px;

            &:hover {
              background-color: $primary-color;
              color: white;
            }
          }
        }
      }
    }
  }
}

// Theming
[data-theme="light"] {
  .divider {
    background-color: $gray-color-shade;
  }

  .error-details {
    background-color: $gray-color-shade;
  }

  .useful-link {
    background-color: $secondary-color-shade;
    color: $primary-color;
  }
}

[data-theme="dark"] {
  .divider {
    background-color: $gray-color-shade;
  }

  .error-details {
    background-color: $dark-gray-color-shade;
  }

  .useful-link {
    background-color: $dark-secondary-color-shade;
    color: $dark-primary-color;
  }
}
