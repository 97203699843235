@import "../../variables.scss";

.dashboard-page-content {
  background-color: white;
  padding: 24px;
  border-radius: 6px;

  display: grid;
  height: fit-content;
  gap: 24px;

  .incidents-reports {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .incident-report {
      flex: 1 1 300px;
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: whitesmoke;
      padding: 24px;
      border-radius: 12px;

      .list-icon {
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 22px;
        color: #8b8b8b;
      }

      .icon {
        background-color: $secondary-color-shade;
        padding: 12px;
        border-radius: 6px;
        color: $secondary-color;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:nth-child(even) {
        .icon {
          background-color: #deeeff;
        }
      }
    }
  }
}

[data-theme="dark"] {
  .dashboard-page-content {
    background-color: $dark-gray-color-shade;

    .incidents-reports {
      .incident-report {
        background-color: $dark-secondary-color-shade;
      }
    }
  }
}

.draft-list {
  .drafts-categories {
    display: grid;
    height: fit-content;
    gap: 2rem;

    .categories {
      display: grid;
      height: fit-content;
      gap: 1rem;

      padding: 1rem;
      border-radius: 12px;

      .categories-title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .cards {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;

        .category {
          flex: 1 1 300px;
          background-color: #dfefff;
          padding: 1rem;
          border-radius: 12px;
          width: 100%;
          max-width: 400px;
        }
      }

      &:nth-child(even) {
        background-color: #fffcfa;
      }
    }
  }
}
