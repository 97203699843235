// colors
// Light theme
$body-background-color: white;
$text-color: #1E1E1E;
$primary-color: #145C9E;
$primary-color-shade: #145b9e26;
$secondary-color: #F87C47;
$secondary-color-shade: #FFF1EB;
$gray-color-shade: #f3f3f3;
$gray-color: #909090;


// Dark theme
$dark-body-background-color: #1E1E1E;
$dark-primary-color: white;
$dark-secondary-color: #F87C47;
$dark-secondary-color-shade: #3c3c3c;
$dark-gray-color-shade: #2C2C2C;