.splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.splash-screen .container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 50px;
}
.splash-screen .container .splash-img {
  width: 50%;
}
.splash-screen .container .splash-img .img::after {
  content: "";
  display: block;
  width: 100%;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.splash-screen .container .splash-content {
  flex: 1;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}

@media screen and (max-width: 720px) {
  .splash-screen .container {
    gap: 24px;
    flex-direction: column;
    padding: 24px;
  }
  .splash-screen .container .splash-img {
    width: 100%;
  }
  .splash-screen .container .splash-img .img::after {
    height: 200px;
  }
  .splash-screen .container .splash-content {
    text-align: center;
  }
  .splash-screen .container .splash-content .button {
    width: 100%;
  }
}
[data-theme=light] .splash-img .img::after {
  background: url("../../../img/pages/splashScreen/splash-image-light.png");
}

[data-theme=dark] .splash-img .img::after {
  background: url("../../../img/pages/splashScreen/splash-image-dark.png");
}/*# sourceMappingURL=splash_screen.css.map */