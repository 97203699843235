.dashboard-page-content {
  background-color: white;
  padding: 24px;
  border-radius: 6px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.dashboard-page-content .incidents-reports {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.dashboard-page-content .incidents-reports .incident-report {
  flex: 1 1 300px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: whitesmoke;
  padding: 24px;
  border-radius: 12px;
}
.dashboard-page-content .incidents-reports .incident-report .list-icon {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 22px;
  color: #8b8b8b;
}
.dashboard-page-content .incidents-reports .incident-report .icon {
  background-color: #FFF1EB;
  padding: 12px;
  border-radius: 6px;
  color: #F87C47;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-page-content .incidents-reports .incident-report:nth-child(even) .icon {
  background-color: #deeeff;
}

[data-theme=dark] .dashboard-page-content {
  background-color: #2C2C2C;
}
[data-theme=dark] .dashboard-page-content .incidents-reports .incident-report {
  background-color: #3c3c3c;
}

.draft-list .drafts-categories {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.draft-list .drafts-categories .categories {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  padding: 1rem;
  border-radius: 12px;
}
.draft-list .drafts-categories .categories .categories-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.draft-list .drafts-categories .categories .cards {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.draft-list .drafts-categories .categories .cards .category {
  flex: 1 1 300px;
  background-color: #dfefff;
  padding: 1rem;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
}
.draft-list .drafts-categories .categories:nth-child(even) {
  background-color: #fffcfa;
}/*# sourceMappingURL=incidentTrackingPage.css.map */