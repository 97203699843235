.number-mrn,
.address,
.location-contribution-diagnosis,
.patient-status,
.outcomes {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 24px;
  padding: 12px;
  border-bottom: 1px solid whitesmoke;

  & > div {
    flex: 1 1 150px;
    display: grid;
    gap: 8px;
    height: fit-content;
  }
}

.number-mrn {
  align-items: start !important;
}
