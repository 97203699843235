@import "../../variables.scss";

body {
  width: 100%;
  position: relative;
}
.overview-content {
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .numbers {
    padding: 12px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 12px;
    background-color: white;
    border-radius: 12px;

    .number {
      flex: 1 1 200px;
      background-color: whitesmoke;
      padding: 24px;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 6px;
      min-width: unset;

      .icon {
        color: $secondary-color;
        background-color: $secondary-color-shade;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .number-title {
        font-size: 14px;
      }

      .content {
        display: grid;
        height: fit-content;
        gap: 3px;

        .count {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .charts-container {
    display: flex;

    flex-direction: column;
    gap: 5px;
    width: 100%;

    .charts-row {
      display: grid;
      grid-template-columns: 1fr;

      .general-incident-progress {
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100% !important;
        width: 100%;
        background-color: #ffffff;
        padding: 20px;

        border-radius: 6px;

        .progress-container {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .progress-col {
            display: flex;
            flex-direction: column;
            gap: 3px;

            > span {
              font-size: 12px;
            }

            .progress-row {
              display: flex;
              gap: 10px;
              justify-content: space-between;
              align-items: center;

              .progress-bar {
                width: 100%;

                progress {
                  border-radius: 7px;
                  width: 100%;
                  height: 15px;
                  appearance: none;
                }

                progress::-webkit-progress-bar {
                  background-color: #ebf5ff;
                  border-radius: 5px;
                }

                progress::-webkit-progress-value {
                  background-color: $primary-color;
                  border-radius: 5px;
                }

                progress::-moz-progress-bar {
                  background-color: #ebf5ff;
                  border-radius: 5px;
                }
              }

              .progress-info {
                color: #626262;
                font-size: 12px;
              }
            }
          }
        }
      }

      .pie-chart-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        .pie-chart {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .label-center {
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: center;

            > span {
              font-size: 14px;
              color: #5f5f5f;
            }

            h4 {
              font-size: 26px;
            }
          }
        }

        .edit-label-center {
          margin-top: -20px;
        }

        .stats {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;

          .stat {
            display: flex;
            gap: 5px;
            align-items: center;
            justify-content: space-between;

            .label {
              color: #626262;
              width: fit-content;
            }

            .value {
              font-weight: bold;
            }
          }
        }

        .edit-medication-stats {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-top: -40px;
          font-size: 12px;
          padding-inline: 20px;

          .stat {
            width: 100%;

            .circle-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
            }
          }
        }
        .edit-stats {
          margin-top: -60px;
        }
        .view-details-orange {
          background-color: $secondary-color;
          color: white;
          padding: 10px 22px;
          border-radius: 8px;
          font-weight: 700;
          width: fit-content;
        }
      }

      .edit-pie-chart-container {
        gap: 0;

        .pie-chart {
          margin-top: -60px;
        }
      }

      .workplace-row {
        display: flex;
        gap: 20px;
        align-items: center;

        span {
          color: #626262;
        }

        h4 {
          font-size: 20px;
        }
      }

      .incident-blocks {
        display: flex;
        gap: 5px;
      }

      .labels {
        display: grid;
        gap: 20px;
        margin-top: 20px;
        font-size: 12px;
        grid-template-columns: 1fr 1fr;

        .label-col {
          display: flex;
          flex-direction: column;
          gap: 30px;

          .label {
            display: flex;
            align-items: center;
            gap: 5px;

            span {
              color: #626262;
            }
          }
        }
      }
    }

    .graph-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      .complaints {
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 6px;

        .complaints-table {
          th,
          td {
            text-align: start;

            .table-actions {
              justify-content: flex-start;
            }
          }
        }
      }
    }

    .graph {
      display: flex;
      flex-direction: column;
      gap: 12px;
      background-color: white;

      border-radius: 6px;

      .header-row {
        padding: 20px;
        padding-bottom: 0;
        .legends {
          display: flex;
          gap: 20px;

          .legend {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }

      .main-graph {
        width: 100%;
      }
    }
  }
}

.grievance-graph {
  padding: 0 !important;
  background-color: white;
  border-radius: 6px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;

  .view-details {
    color: $secondary-color;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
  }
}

.edit-header-row {
  padding: 20px;
  padding-bottom: 0;
}

.lost-found {
  justify-content: center;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  min-width: 10px;
}

.block {
  border-radius: 6px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;
  font-weight: 800;
}

.blue {
  background-color: $primary-color;
  color: white;
}

.orange {
  background-color: $secondary-color;
  color: white;
}

.green {
  background-color: #208781;
  color: white;
}

.light-blue {
  background-color: #bbddfc;
  color: $primary-color;
}

.light-orange {
  background-color: #ffd2bf;
  color: $primary-color;
}

.light-green {
  background-color: #9fe4e0;
}

.overview-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  cursor: pointer;

  .filter {
    padding: 0.8rem;
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    min-width: 200px;
    max-width: 400px;

    position: relative;

    .filters-options {
      width: 100%;
      max-width: 400px;
      position: absolute;
      top: 3rem;
      z-index: 2;
      background-color: white;
      padding: 1rem 0;
      border-radius: 0.5rem;
      left: 0;
      right: 0;
      display: none;
      height: fit-content;
      overflow-y: hidden;
      box-shadow: 2px 4px 31px 4px rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      .filter-option {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        position: relative;
        padding: 0.8rem 1rem;
        text-wrap: nowrap;
        font-size: 12px;
        transition: background-color 0.2s ease-in-out;
        // background-color: red;

        &::before {
          content: "";
          width: 100%;
          height: 1px;
          background-color: whitesmoke;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          transform: translateY(-50%);
        }

        &:hover {
          background-color: $primary-color;
          color: white;
        }
      }
    }
  }

  .open {
    border-radius: 0.5rem 0.5rem 0 0;

    .filters-options {
      border-radius: 0 0 0.5rem 0.5rem;
      display: grid;
      animation: openFilters 0.3s ease-in-out forwards;
    }
  }

  .close {
    animation: closeFilters 0.3s ease-in-out forwards;
  }

  .date-range {
    padding: 1rem;
    display: grid;
    height: fit-content;
    gap: 0.5rem;
    border: 1px solid whitesmoke;
  }
}

.overview-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  cursor: pointer;

  .filter {
    padding: 0.8rem;
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    min-width: 200px;
    max-width: 400px;

    position: relative;

    .filters-options {
      width: 100%;
      max-width: 400px;
      position: absolute;
      top: 3rem;
      z-index: 2;
      background-color: white;
      padding: 1rem 0;
      border-radius: 0.5rem;
      left: 0;
      right: 0;
      display: none;
      height: fit-content;
      overflow-y: hidden;
      box-shadow: 2px 4px 31px 4px rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      .filter-option {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        position: relative;
        padding: 0.8rem 1rem;
        text-wrap: nowrap;
        font-size: 12px;
        transition: background-color 0.2s ease-in-out;
        // background-color: red;

        &::before {
          content: "";
          width: 100%;
          height: 1px;
          background-color: whitesmoke;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          transform: translateY(-50%);
        }

        &:hover {
          background-color: $primary-color;
          color: white;
        }
      }
    }
  }

  .open {
    border-radius: 0.5rem 0.5rem 0 0;

    .filters-options {
      border-radius: 0 0 0.5rem 0.5rem;
      display: grid;
      animation: openFilters 0.3s ease-in-out forwards;
    }
  }

  .close {
    animation: closeFilters 0.3s ease-in-out forwards;
  }

  .date-range {
    padding: 1rem;
    display: grid;
    height: fit-content;
    gap: 0.5rem;
    border: 1px solid whitesmoke;
  }
}

.swiper {
  width: 100% !important;
  height: 100%;
  padding-bottom: 30px !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 20px;

  padding-block: 10px;

  color: $primary-color;
  -webkit-box-shadow: 0px 14px 31px -6px rgba(0, 0, 0, 0.196);
  -moz-box-shadow: 0px 14px 31px -6px rgba(0, 0, 0, 0.196);
  box-shadow: 0px 14px 31px -6px rgba(0, 0, 0, 0.196);
  font-size: 30px !important;
}

.swiper-button-next {
  right: 30px;
}

.swiper-button-prev {
  left: 30px;
}

.swiper-button-disabled {
  display: none;
}

.swiper-pagination {
  bottom: 5px !important;
}
:root {
  --swiper-theme-color: #f87c47;
}

.main-graph {
  height: 400px !important;
}
@media screen and (min-width: 1540px) {
  .overview-content {
    .numbers {
      .number {
        min-width: 300px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .workplace-chart {
    grid-area: area1;
  }

  .overview-content {
    .charts-container {
      .graph-row {
        grid-template-columns: repeat(1, 1fr);

        .complaints-table {
          display: none;
        }
      }
    }

    .mobile-table {
      display: grid;
      height: fit-content;
      gap: 1rem;

      .table-card {
        padding: 1rem;
        border: 1px solid #e3e3e3;
        border-radius: 0.5rem;
        display: grid;
        height: fit-content;
        gap: 2rem;

        label {
          color: gray;
        }

        .card-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;

          .id-number {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .card-actions {
            display: flex;
            align-items: center;
            color: $primary-color;
          }
        }

        .card-content-items {
          display: grid;
          height: fit-content;
          gap: 1rem;
        }
      }
    }
  }

  .overview-filters {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .overview-content {
    .charts-container {
      gap: 30px;
    }
  }
  .swiper {
    width: 100% !important;
    height: 100%;
    padding-bottom: 70px !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    bottom: -50px !important;
    background-color: orange !important;
    top: 87%;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    -webkit-box-shadow: 0px 14px 31px -8px rgba(0, 0, 0, 0.196);
    -moz-box-shadow: 0px 14px 31px -8px rgba(0, 0, 0, 0.196);
    box-shadow: 0px 14px 31px -8px rgba(0, 0, 0, 0.196);
  }

  .swiper-button-next {
    right: 15px;
  }
  .swiper-button-prev {
    left: 15px;
  }

  .swiper-pagination {
    bottom: 20px !important;
  }
}
