.dashboard-container {
  display: flex;
  align-items: start;
  width: 100vw;
}
.dashboard-container .side-bar {
  width: 280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  bottom: 0;
}
.dashboard-container .side-bar .links {
  display: grid;
  gap: 12px;
  height: -moz-fit-content;
  height: fit-content;
}
.dashboard-container .side-bar .links .branding {
  height: 80px;
  display: flex;
  padding: 24px;
}
.dashboard-container .side-bar .links .branding .logo {
  display: flex;
  align-items: center;
  gap: 6px;
}
.dashboard-container .side-bar .links .branding .logo .site-title {
  color: white;
  font-size: 16px;
}
.dashboard-container .side-bar .links .branding .logo img {
  width: 50px;
}
.dashboard-container .side-bar .links .menu-links {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}
.dashboard-container .side-bar .links .menu-links .link {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 6px;
  text-wrap: nowrap;
}
.dashboard-container .side-bar .links .menu-links .link .icon {
  height: 28px;
  width: 28px;
  border-radius: 6px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-container .side-bar .menu-actions {
  padding: 24px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}
.dashboard-container .side-bar .menu-actions .link {
  display: flex;
  gap: 12px;
}
.dashboard-container .dashboard-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.dashboard-container .dashboard-content .dash-header {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  padding: 12px;
  z-index: 100;
  border-bottom: 1px solid whitesmoke;
}
.dashboard-container .dashboard-content .dash-header .header-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}
.dashboard-container .dashboard-content .dash-header .header-actions .notification,
.dashboard-container .dashboard-content .dash-header .header-actions .profile {
  height: 52px;
  width: 52px;
  border: 1px solid #145C9E;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.3rem;
  cursor: pointer;
}
.dashboard-container .dashboard-content .dash-header .header-actions .notification .dot,
.dashboard-container .dashboard-content .dash-header .header-actions .profile .dot {
  position: absolute;
  top: 5px;
  left: 24px;
  padding: 3px;
  display: flex;
  min-width: 17px;
  min-height: 17px;
  justify-content: center;
  align-items: center;
  background-color: #F87C47;
  border-radius: 10px;
  z-index: 3;
}
.dashboard-container .dashboard-content .dash-header .header-actions .notification .dot span,
.dashboard-container .dashboard-content .dash-header .header-actions .profile .dot span {
  font-size: 10px;
  font-weight: 800;
  color: white;
}
.dashboard-container .dashboard-content .dash-header .header-actions .notification .shake,
.dashboard-container .dashboard-content .dash-header .header-actions .profile .shake {
  display: inline-block;
  transform-origin: top center;
  color: #F87C47;
}
.dashboard-container .dashboard-content .dash-header .header-actions .profile img {
  -o-object-fit: cover;
     object-fit: cover;
}

[data-theme=light] .side-bar {
  background-color: #145C9E;
  color: white;
}
[data-theme=light] .side-bar .links .menu-links .active {
  background-color: #F87C47;
}
[data-theme=light] .side-bar .links .branding {
  background-color: #0f589d;
}
[data-theme=light] .dashboard-content {
  background-color: #f3f3f3;
}
[data-theme=light] .dashboard-content .dash-header {
  background-color: white;
}
[data-theme=light] .dashboard-content .dash-header .mobile-menu-button {
  border-color: #f3f3f3;
}
[data-theme=light] .dashboard-content .dash-header .mobile-menu-button .bar {
  background-color: #1E1E1E;
}

[data-theme=dark] .side-bar {
  background-color: rgb(19, 19, 19);
}
[data-theme=dark] .side-bar .links .menu-links .active {
  background-color: #2C2C2C;
}
[data-theme=dark] .side-bar .links .branding {
  background-color: rgb(13, 13, 13);
}
[data-theme=dark] .dashboard-content {
  background-color: #1E1E1E;
}
[data-theme=dark] .dashboard-content .dash-header {
  background-color: #2C2C2C;
}
[data-theme=dark] .dashboard-content .dash-header .mobile-menu-button {
  border-color: white;
}
[data-theme=dark] .dashboard-content .dash-header .mobile-menu-button .bar {
  background-color: white;
}

small {
  color: gray;
}

.profile-card {
  position: absolute;
  top: 4.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  right: 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.profile-card .profile-card-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f9f9f9;
  padding: 0.5rem 1rem;
  text-wrap: nowrap;
  border-radius: 0.5rem;
}
.profile-card .links {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1.3rem;
}
.profile-card .links .link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  cursor: pointer;
}
.profile-card .links .special {
  color: #F87C47;
}
.profile-card .divider {
  border: 1px solid #f9f9f9;
  width: 100%;
}
.profile-card .switch-accounts {
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  gap: 1rem;
  max-height: 3.5rem;
  width: 260px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.profile-card .switch-accounts .current-account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
}
.profile-card .switch-accounts .current-account .link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.profile-card .switch-accounts .accounts-list {
  display: grid;
  gap: 0.5rem;
  top: 3rem;
}
.profile-card .switch-accounts .accounts-list .account {
  background-color: #e8f2fc;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #145C9E;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.profile-card .switch-accounts .accounts-list .account .account-icon {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #145C9E;
  color: white;
  border-radius: 0.2rem;
}
.profile-card .switch-accounts .icon {
  transition: all 0.5s ease-in-out;
}
.profile-card .show {
  max-height: 500px;
}
.profile-card .show .icon {
  transform: rotate(90deg);
}

.notifications-container {
  position: absolute;
  top: 4.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  right: 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
}
.notifications-container .notifications-tabs {
  display: flex;
  align-items: center;
}
.notifications-container .notifications-tabs .tab {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 2px solid #f7f7f7;
  padding: 1rem;
  cursor: pointer;
}
.notifications-container .notifications-tabs .active {
  border-bottom: 2px solid #F87C47;
}
.notifications-container .no-notifications {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.notifications-container .tab-content .notification-list {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.notifications-container .tab-content .notification-list .notification-container {
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.2rem;
  position: relative;
  transition: 0.5s ease;
}
.notifications-container .tab-content .notification-list .notification-container:hover {
  background-color: #ededed;
}
.notifications-container .tab-content .notification-list .notification-container .delete-icon {
  transition: 0.5s ease;
}
.notifications-container .tab-content .notification-list .notification-container .delete-icon:hover {
  color: #F87C47;
}
.notifications-container .tab-content .notification-list .notification-container .notification-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.notifications-container .tab-content .notification-list .notification-container .time-status {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.notifications-container .tab-content .notification-list .notification-container .status .read,
.notifications-container .tab-content .notification-list .notification-container .status .unread {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}
.notifications-container .tab-content .notification-list .notification-container .status .read {
  color: #145C9E;
}
.notifications-container .tab-content .notification-list .notification-container .status .unread {
  color: #F87C47;
}
.notifications-container .tab-content .notification-list .action-icon {
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  cursor: pointer;
  color: gray;
  display: flex;
  align-items: center;
  background-color: transparent;
}
.notifications-container .tab-content .notification-list .action-icon .view-details {
  text-wrap: nowrap;
  max-width: 0;
  overflow: hidden;
  transition: all 1s ease;
  opacity: 0;
}
.notifications-container .not-icon {
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8f2fc;
  border-radius: 50%;
}
.notifications-container .warning {
  background-color: #fffaee;
  color: #ffb708;
}
.notifications-container .error {
  background-color: white;
  color: red;
}
.notifications-container .notifications-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.notifications-container .notifications-actions .action {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.notifications-container .notifications-actions .view-all {
  color: #F87C47;
}

.welcome-page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
.welcome-page-content .quick-actions {
  margin: auto;
  border: 1px solid #f9f3f3;
  padding: 2rem;
  border-radius: 1rem;
  max-width: 800px;
  width: 100%;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.welcome-page-content .quick-actions .card .actions-grid {
  display: flex;
  align-items: stretch;
  gap: 1rem;
  flex-wrap: wrap;
}
.welcome-page-content .quick-actions .card .actions-grid .action {
  cursor: pointer;
  flex: 1 1 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 1px solid #f9f3f3;
  padding: 1rem;
  background-color: #feece4;
  border-radius: 0.5rem;
  transition: 0.3s linear;
}
.welcome-page-content .quick-actions .card .actions-grid .action > :first-child {
  color: #F87C47;
}
.welcome-page-content .quick-actions .card .actions-grid .action:hover {
  background-color: #F87C47;
  color: white;
}
.welcome-page-content .quick-actions .card .actions-grid .action:hover > :first-child {
  color: white;
}
.welcome-page-content .quick-actions .card .actions-grid .action span {
  text-wrap: nowrap;
}

@media screen and (max-width: 1080px) {
  .dashboard-container .side-bar {
    width: -moz-fit-content;
    width: fit-content;
  }
  .dashboard-container .side-bar .links {
    justify-items: center;
  }
  .dashboard-container .side-bar .links .branding .logo .site-title {
    display: none;
  }
  .dashboard-container .side-bar .links .menu-links {
    justify-items: center;
  }
  .dashboard-container .side-bar .links .menu-links .link .link-text {
    display: none;
  }
  .dashboard-container .side-bar .menu-actions {
    justify-items: center;
  }
  .dashboard-container .side-bar .menu-actions .link .link-text {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  .dashboard-container .side-bar {
    position: absolute;
    display: none;
    width: 100%;
    justify-content: start;
    gap: 50px;
    padding-top: 50px;
  }
  .dashboard-container .side-bar .links .branding {
    display: none;
  }
  .dashboard-container .side-bar .links .menu-links {
    justify-self: start;
    width: 100%;
  }
  .dashboard-container .side-bar .links .menu-links .link {
    width: 100%;
  }
  .dashboard-container .side-bar .links .menu-links .link .link-text {
    display: block;
  }
  .dashboard-container .side-bar .menu-actions {
    justify-self: start;
    width: 100%;
  }
  .dashboard-container .side-bar .menu-actions .link {
    width: 100%;
  }
  .dashboard-container .side-bar .menu-actions .link .link-text {
    display: block;
  }
  .dashboard-container .is-menu-open {
    z-index: 2000;
    display: flex;
    top: 80px;
  }
  .dashboard-container .dash-header .mobile-menu-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 52px;
    height: 52px;
    border: 1px solid transparent;
    padding: 8px;
    border-radius: 12px;
  }
  .dashboard-container .dash-header .mobile-menu-button .bar {
    width: 100% px;
    height: 8px;
    background-color: transparent;
    border-radius: 2px;
    transition: transform 0.3s ease;
  }
  .dashboard-container .dash-header .mobile-menu-button .middle {
    width: 80%;
  }
  .dashboard-container .dash-header .open {
    justify-content: center;
  }
  .dashboard-container .dash-header .open .middle {
    display: none;
  }
  .dashboard-container .dash-header .open .first {
    transform: rotate(45deg) translate(3px, 3px);
  }
  .dashboard-container .dash-header .open .last {
    transform: rotate(-45deg) translate(2px, -2px);
  }
  .dashboard-container .dash-header .header-actions {
    flex-direction: row-reverse;
  }
  .dashboard-container .dash-header .header-actions .new-action-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    padding: 0;
    height: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dashboard-container .dash-header .header-actions .new-action-button span {
    display: none;
  }
}
.facility-card {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #e8f2fc;
  padding: 1rem;
  border: 1px solid #60a9eb;
  border-radius: 0.8rem;
}
.facility-card .facility {
  color: gray;
}

.padding {
  display: none;
  border: 1px solid #60a9eb;
  border-radius: 0.8rem;
  margin: 1rem;
  background-color: #e8f2fc;
}/*# sourceMappingURL=dash_container.css.map */