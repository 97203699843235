@import "../../variables.scss";

.profile-information {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;

  .basic-info {
    display: grid;
    height: fit-content;
    gap: 1rem;

    .profile-info {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 1rem;
      height: fit-content;
      border: 1px solid whitesmoke;
      padding: 2rem;
      border-radius: 0.5rem;

      small {
        color: gray;
      }

      .profile-image {
        width: 4rem;
        height: 4rem;
        padding: 1rem;
        border-radius: 50%;
        object-fit: contain;
        border: 1px solid whitesmoke;
      }

      .positions {
        display: flex;
        align-items: center;
        gap: 1rem;

        .position {
          display: flex;
          align-items: center;
          gap: 0.2rem;

          .dot {
            height: 0.6rem;
            width: 0.6rem;
            border-radius: 50%;
            background-color: $primary-color;
          }
        }
      }

      .profile-actions {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    .personal-info {
      position: relative;
      padding: 2rem;
      border-radius: 0.5rem;
      border: 1px solid whitesmoke;
      display: grid;
      height: fit-content;
      gap: 2rem;

      small {
        color: gray;
        font-size: 1rem;
      }

      small {
        color: gray;
      }

      p {
        font-size: 1rem;
      }

      .profile-items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        flex-wrap: wrap;

        .item {
          display: grid;
          height: fit-content;
          gap: 0.5rem;
        }

        .full {
          display: grid;
          height: fit-content;
          gap: 0.5rem;
          grid-column: span 3;
        }

        .permissions {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 1rem;

          .permission {
            text-wrap: nowrap;
            background-color: lighten($color: $primary-color, $amount: 60);
            padding: 0.5rem 1rem;
            border-radius: 0.4rem;
          }
        }
      }
    }
  }
}

.profile-data {
  border: 1px solid whitesmoke;
  border-radius: 0.5rem;
  width: 100%;

  .profile-tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid whitesmoke;
    padding: 1rem 2rem;

    .tab {
      padding: 0.8rem 1rem;
      font-weight: bold;
      border-radius: 0.5rem;
      text-wrap: nowrap;
    }

    .active {
      background-color: lighten($color: $secondary-color, $amount: 30);
      color: $secondary-color;
    }
  }

  .tabs-content {
    padding: 1rem;
    font-size: 1rem;
    display: grid;
    height: fit-content;
    gap: 2rem;
    width: 100%;

    small {
      color: gray;
    }

    .reports {
      display: grid;
      height: fit-content;
      gap: 1rem;

      .user-report {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon {
          background-color: lighten($color: $primary-color, $amount: 60);
          color: $primary-color;
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        border-bottom: 1px dashed whitesmoke;
        padding-bottom: 1rem;
      }

      .draft {
        .icon {
          background-color: lighten($color: $secondary-color, $amount: 30);
          color: $secondary-color;
        }
      }
    }
  }
}

.user-complain-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .form {
    position: absolute;
    max-width: 600px;
    width: 100%;
    padding: 2rem;
    padding-top: 3rem;
    border-radius: 0.5rem;
    background-color: white;
    height: 90%;
    overflow: auto;
    display: grid;
    gap: 1rem;

    .close-popup {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
  }
}

.has-height {
  position: relative;
}

.assigned-staff-list,
.suggestions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  .staff {
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: lighten($color: $primary-color, $amount: 60);
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 1px solid lighten($color: $primary-color, $amount: 40);
  }
}

.complaints {
  display: grid;
  height: fit-content;
  gap: 2rem;

  button {
    width: fit-content;
  }
}

.user-complains {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  .complain-content {
    flex: 1 1 200px;
    padding: 1rem;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(233, 233, 233);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    border-radius: 0.5rem;

    .card {
      display: grid;
      height: fit-content;
      gap: 0.5rem;
    }

    .name-mr {
      display: flex;
      align-items: center;

      .icon {
        height: 2rem;
        width: 2rem;
        background-color: lighten($color: $primary-color, $amount: 50);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: $primary-color;
      }
    }
  }
}

.documents-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background-color: whitesmoke;

  .document {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    position: relative;

    .icon {
      max-width: 2rem;
    }

    .download-icon {
      border: 1px solid rgb(229, 229, 229);
      height: 2.2rem;
      width: 2.2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary-color;
    }

    .delete-document {
      position: absolute;
      top: -1rem;
      right: -1rem;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: white;
      padding: 0.2rem;
    }
  }

  .document-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
  }
}

.complain-details-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .complaint-details {
    background-color: white;
    padding: 2rem 1rem;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    display: grid;
    height: fit-content;
    gap: 2rem;
    position: relative;
    max-height: 90%;
    overflow: auto;

    .items-group {
      border: 1px solid rgb(230, 230, 230);
      padding: 1rem;
      border-radius: 0.5rem;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      .full {
        grid-column: span 2;
      }
    }

    .close-icon {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }

    .buttons {
      width: 100%;
      justify-content: end;
    }

    .send-to-department {
      width: 100%;
    }
  }
}

.actions-button {
  position: relative;

  .actions {
    position: absolute;
    top: 3rem;
    right: 0;
    width: fit-content;
    background-color: rgb(248, 248, 248);
    z-index: 2;
    border-radius: 0.5rem;
    padding: 1rem;
    display: grid;
    height: fit-content;
    gap: 1rem;
    color: black;
    border: 1px solid rgb(237, 237, 237);

    .action {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border: none;
      background-color: transparent;
      transition: background-color 0.2s ease-in-out;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &:hover {
        background-color: lighten($color: $secondary-color, $amount: 30);
        color: $secondary-color;
      }
    }
  }
}

.change-password-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  form {
    padding: 0;
  }

  .change-password {
    background-color: white;
    padding: 2rem 1rem;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    display: grid;
    height: fit-content;
    gap: 1rem;
    position: relative;
    max-height: 90%;
    overflow: auto;

    .close-icon {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }
  }

  .link {
    color: $secondary-color;
  }
}

@media screen and (max-width: 1440px) {
  .user-complains {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1280px) {
  .user-complains {
    grid-template-columns: 1fr 1fr;
  }

  .profile-information {
    grid-template-columns: 1fr;

    .profile-data {
      .profile-tabs {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .complain-details-popup {
    padding: 1rem;

    .complaint-details {
      .items-group {
        grid-template-columns: 1fr;
      }
    }
  }

  .user-complains {
    grid-template-columns: 1fr;
  }

  .profile-information {
    .basic-info {
      .profile-info {
        .profile-actions {
          flex-direction: column;
        }
      }

      .personal-info {
        .profile-items {
          grid-template-columns: 1fr 1fr;
          // display: none;

          .full {
            grid-column: span 2;
          }
        }
      }
    }
  }
}
