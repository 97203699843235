.preference-popup-content {
  position: relative;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2rem;
  background-color: white;
  width: 100%;
  max-width: 600px;
  border-radius: 0.5rem;
}
.preference-popup-content .preferences-form {
  display: grid;
  gap: 1rem;
}

.searchable-select {
  position: relative;
  width: 100%;
}
.searchable-select .select-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease;
}
.searchable-select .select-trigger:hover {
  border-color: #cbd5e1;
}
.searchable-select .select-trigger.open {
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}
.searchable-select .select-trigger.error {
  border-color: #ef4444;
}
.searchable-select .select-trigger .selected-value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.searchable-select .select-trigger .trigger-icon {
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  color: #64748b;
  transition: transform 0.2s ease;
}
.searchable-select .select-trigger.open .trigger-icon {
  transform: rotate(180deg);
}
.searchable-select .select-dropdown {
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  right: 0;
  z-index: 50;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.searchable-select .select-dropdown .search-container {
  position: relative;
  padding: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
}
.searchable-select .select-dropdown .search-container .search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: #64748b;
}
.searchable-select .select-dropdown .search-container input {
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 2.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  outline: none;
  transition: all 0.2s ease;
}
.searchable-select .select-dropdown .search-container input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}
.searchable-select .select-dropdown .search-container input::-moz-placeholder {
  color: #94a3b8;
}
.searchable-select .select-dropdown .search-container input::placeholder {
  color: #94a3b8;
}
.searchable-select .select-dropdown .options-container {
  max-height: 15rem;
  overflow-y: auto;
}
.searchable-select .select-dropdown .options-container .option {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.searchable-select .select-dropdown .options-container .option:hover {
  background-color: #f8fafc;
}
.searchable-select .select-dropdown .options-container .option.selected {
  background-color: #eff6ff;
  color: #3b82f6;
}
.searchable-select .select-dropdown .options-container .no-options {
  padding: 0.75rem;
  text-align: center;
  color: #64748b;
}
.searchable-select .error-message {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #ef4444;
}/*# sourceMappingURL=preferences.css.map */