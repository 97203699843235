.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.new-user-form-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.308);
}
.new-user-form-popup button {
  width: -moz-fit-content;
  width: fit-content;
}
.new-user-form-popup .form {
  display: flex;
  flex-direction: column;
  height: 90%;
  gap: 1rem;
  overflow: auto;
}
.new-user-form-popup form {
  padding: 0;
}
.new-user-form-popup .popup-content {
  max-width: 700px;
  width: 100%;
  background-color: white;
  padding: 2rem;
  padding-top: 4rem;
  border-radius: 0.5rem;
  position: relative;
  height: 680px;
  overflow: auto;
  position: relative;
}
.new-user-form-popup .popup-content .close-icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.new-user-form-popup .popup-content .buttons {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.mobile-users {
  display: none;
  max-height: 80vh;
  overflow: auto;
  gap: 1rem;
}
.mobile-users .user-card {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: whitesmoke;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}

.form-tabs {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: space-between;
}
.form-tabs > :first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}
.form-tabs > :last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}
.form-tabs .tab {
  flex: 1;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: whitesmoke;
  cursor: pointer;
  justify-content: center;
}
.form-tabs .active {
  background-color: #F87C47;
  color: white;
}

.form-choices {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.form-choices .choice {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  background-color: whitesmoke;
  padding: 0.8rem;
  border-radius: 0.5rem;
  color: rgb(74, 74, 74);
}
.form-choices .choice:hover {
  background-color: #fee5da;
}
.form-choices .selected {
  background-color: #F87C47;
  color: white;
}

@media screen and (max-width: 768px) {
  .mobile-users {
    display: grid;
  }
  .actions {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    position: relative;
  }
  .actions .filters {
    width: 100%;
  }
  .actions .filters input {
    flex: 1;
  }
  .actions .new-user-button {
    position: absolute;
    right: 0;
  }
  .new-user-form-popup {
    padding: 1rem;
  }
  .new-user-form-popup .popup-content {
    padding: 1rem;
  }
  .new-user-form-popup .popup-content button {
    width: 100%;
  }
  .new-user-form-popup .form {
    padding-top: 2rem;
  }
  .new-user-form-popup .form .form-tabs span {
    display: none;
  }
  .users-table {
    display: none;
  }
}/*# sourceMappingURL=users.css.map */