.profile-information {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}
.profile-information .basic-info {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.profile-information .basic-info .profile-info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid whitesmoke;
  padding: 2rem;
  border-radius: 0.5rem;
}
.profile-information .basic-info .profile-info small {
  color: gray;
}
.profile-information .basic-info .profile-info .profile-image {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  border-radius: 50%;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid whitesmoke;
}
.profile-information .basic-info .profile-info .positions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.profile-information .basic-info .profile-info .positions .position {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.profile-information .basic-info .profile-info .positions .position .dot {
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  background-color: #145C9E;
}
.profile-information .basic-info .profile-info .profile-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.profile-information .basic-info .personal-info {
  position: relative;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid whitesmoke;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.profile-information .basic-info .personal-info small {
  color: gray;
  font-size: 1rem;
}
.profile-information .basic-info .personal-info small {
  color: gray;
}
.profile-information .basic-info .personal-info p {
  font-size: 1rem;
}
.profile-information .basic-info .personal-info .profile-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  flex-wrap: wrap;
}
.profile-information .basic-info .personal-info .profile-items .item {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}
.profile-information .basic-info .personal-info .profile-items .full {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
  grid-column: span 3;
}
.profile-information .basic-info .personal-info .profile-items .permissions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.profile-information .basic-info .personal-info .profile-items .permissions .permission {
  text-wrap: nowrap;
  background-color: #e8f2fc;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}

.profile-data {
  border: 1px solid whitesmoke;
  border-radius: 0.5rem;
  width: 100%;
}
.profile-data .profile-tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid whitesmoke;
  padding: 1rem 2rem;
}
.profile-data .profile-tabs .tab {
  padding: 0.8rem 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  text-wrap: nowrap;
}
.profile-data .profile-tabs .active {
  background-color: #fee5da;
  color: #F87C47;
}
.profile-data .tabs-content {
  padding: 1rem;
  font-size: 1rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
  width: 100%;
}
.profile-data .tabs-content small {
  color: gray;
}
.profile-data .tabs-content .reports {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.profile-data .tabs-content .reports .user-report {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px dashed whitesmoke;
  padding-bottom: 1rem;
}
.profile-data .tabs-content .reports .user-report .icon {
  background-color: #e8f2fc;
  color: #145C9E;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-data .tabs-content .reports .draft .icon {
  background-color: #fee5da;
  color: #F87C47;
}

.user-complain-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-complain-form .form {
  position: absolute;
  max-width: 600px;
  width: 100%;
  padding: 2rem;
  padding-top: 3rem;
  border-radius: 0.5rem;
  background-color: white;
  height: 90%;
  overflow: auto;
  display: grid;
  gap: 1rem;
}
.user-complain-form .form .close-popup {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.has-height {
  position: relative;
}

.assigned-staff-list,
.suggestions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.assigned-staff-list .staff,
.suggestions .staff {
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: #e8f2fc;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #8dc1f1;
}

.complaints {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.complaints button {
  width: -moz-fit-content;
  width: fit-content;
}

.user-complains {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.user-complains .complain-content {
  flex: 1 1 200px;
  padding: 1rem;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(233, 233, 233);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  border-radius: 0.5rem;
}
.user-complains .complain-content .card {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}
.user-complains .complain-content .name-mr {
  display: flex;
  align-items: center;
}
.user-complains .complain-content .name-mr .icon {
  height: 2rem;
  width: 2rem;
  background-color: #bbdaf6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #145C9E;
}

.documents-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background-color: whitesmoke;
}
.documents-list .document {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: relative;
}
.documents-list .document .icon {
  max-width: 2rem;
}
.documents-list .document .download-icon {
  border: 1px solid rgb(229, 229, 229);
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F87C47;
}
.documents-list .document .delete-document {
  position: absolute;
  top: -1rem;
  right: -1rem;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  padding: 0.2rem;
}
.documents-list .document-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.complain-details-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.complain-details-popup .complaint-details {
  background-color: white;
  padding: 2rem 1rem;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
  position: relative;
  max-height: 90%;
  overflow: auto;
}
.complain-details-popup .complaint-details .items-group {
  border: 1px solid rgb(230, 230, 230);
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.complain-details-popup .complaint-details .items-group .full {
  grid-column: span 2;
}
.complain-details-popup .complaint-details .close-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.complain-details-popup .complaint-details .buttons {
  width: 100%;
  justify-content: end;
}
.complain-details-popup .complaint-details .send-to-department {
  width: 100%;
}

.actions-button {
  position: relative;
}
.actions-button .actions {
  position: absolute;
  top: 3rem;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgb(248, 248, 248);
  z-index: 2;
  border-radius: 0.5rem;
  padding: 1rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  color: black;
  border: 1px solid rgb(237, 237, 237);
}
.actions-button .actions .action {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.actions-button .actions .action:hover {
  background-color: #fee5da;
  color: #F87C47;
}

.change-password-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.change-password-popup form {
  padding: 0;
}
.change-password-popup .change-password {
  background-color: white;
  padding: 2rem 1rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  position: relative;
  max-height: 90%;
  overflow: auto;
}
.change-password-popup .change-password .close-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.change-password-popup .link {
  color: #F87C47;
}

@media screen and (max-width: 1440px) {
  .user-complains {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1280px) {
  .user-complains {
    grid-template-columns: 1fr 1fr;
  }
  .profile-information {
    grid-template-columns: 1fr;
  }
  .profile-information .profile-data .profile-tabs {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .complain-details-popup {
    padding: 1rem;
  }
  .complain-details-popup .complaint-details .items-group {
    grid-template-columns: 1fr;
  }
  .user-complains {
    grid-template-columns: 1fr;
  }
  .profile-information .basic-info .profile-info .profile-actions {
    flex-direction: column;
  }
  .profile-information .basic-info .personal-info .profile-items {
    grid-template-columns: 1fr 1fr;
  }
  .profile-information .basic-info .personal-info .profile-items .full {
    grid-column: span 2;
  }
}/*# sourceMappingURL=profile.css.map */