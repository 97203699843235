.no-tickets {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}
.no-tickets p {
  max-width: 400px;
}

.tickets-page .table-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.tickets-page .table-filters .search {
  flex: 1;
}

.mobile-tickets {
  display: none;
}

.filter-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 1080px) {
  .table-filters .hide .new {
    display: none;
  }
  .table-filters button {
    padding: 14px;
  }
  .table-filters button span {
    display: none;
  }
  .table-filters .filter-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .table-tickets {
    display: none;
  }
  .mobile-tickets {
    display: grid;
    gap: 1rem;
  }
  .mobile-tickets button {
    padding: 14px;
  }
  .mobile-tickets .tickets {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .mobile-tickets .tickets .ticket {
    flex: 1 1 300px;
    display: grid;
    gap: 1rem;
    border: 1px solid #F5F5F5;
    border-radius: 0.8rem;
    padding: 1rem;
  }
  .mobile-tickets .tickets .ticket small {
    color: gray;
  }
  .mobile-tickets .tickets .ticket .number-priority {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-tickets .tickets .ticket .title-date-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-tickets .tickets .ticket .title-date-actions .ticket-actions {
    display: flex;
    gap: 1rem;
  }
  .mobile-tickets .tickets .ticket .description {
    display: none;
  }
  .mobile-tickets .tickets .ticket:hover .description {
    display: block;
  }
  .mobile-select-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}/*# sourceMappingURL=support_tickets.css.map */