@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.check-permissions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100vh;
  width: 100vw;
  color: gray;
}

.breadcrumbs-container {
  padding: 6px 12px;
  border-bottom: 1px solid whitesmoke;
}
.breadcrumbs-container .breadcrumbs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.breadcrumbs-container .breadcrumbs .current-page {
  color: #F87C47;
}

.staff-list {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.staff-list button {
  width: -moz-fit-content;
  width: fit-content;
}

.branding {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.branding img {
  max-width: 5rem;
}
.branding h1 {
  font-size: 1.2rem;
}

.link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.inline-link {
  color: #F87C47;
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
}

.delete-form {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}

.support-link {
  background-color: rgba(0, 68, 255, 0.3);
  padding: 1rem;
  border-radius: 0.5rem;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: auto;
}

body {
  background-color: white;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
}

.report-error-link .error-link {
  color: #F87C47;
  text-decoration: underline;
  font-style: italic;
}

.form-errors {
  padding: 24px;
  background-color: #ff8181;
  color: black;
  border-radius: 12px;
}

.theme-switcher {
  cursor: pointer;
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.theme-switcher .icon {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.theme-switcher .icon i {
  font-size: 22px;
}
.theme-switcher .dark-icon {
  background-color: #1E1E1E;
  color: white;
}
.theme-switcher .light-icon {
  background-color: white;
  color: #1E1E1E;
}
.theme-switcher .switch-text {
  text-wrap: nowrap;
}
.theme-switcher .switch-text .text {
  display: none;
}
.theme-switcher:hover .switch-text {
  text-wrap: nowrap;
}
.theme-switcher:hover .switch-text .text {
  display: block;
  text-wrap: nowrap;
}

.categories .row {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.categories .row .delete-draft {
  background-color: #F87C47;
  color: white;
}
.categories .row .processing {
  background-color: #145C9E;
  color: white;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 12px;
}
.buttons .Exit-button {
  color: #3c3c3c;
  border: none;
  cursor: pointer;
  font-size: 12px;
  padding: 12px 40px 12px;
  gap: 10px;
}
.buttons .Exit-button:hover {
  background-color: #f3f3f3;
}
.buttons .Exit-button:active {
  background-color: #909090;
}
.buttons .Exit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px F2F2F2(0, 128, 0, 0.5);
}

small {
  word-break: break-all;
}

button,
.button {
  padding: 12px 26px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  text-wrap: nowrap;
  transition: all 0.3s linear;
}
button:disabled,
.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@keyframes appear {}
.documentation-link {
  display: flex;
  align-items: center;
}
.documentation-link a {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  color: #145C9E;
  background-color: #e8f2fc;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #bbdaf6;
  margin-left: 0.5rem;
}

input,
.search-input,
textarea {
  border: 1px solid #f3f3f3;
}
input::-moz-placeholder, .search-input::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
}
input::placeholder,
.search-input::placeholder,
textarea::placeholder {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.selected {
  background-color: #ffece3;
}

.results-table {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.results-table .count {
  padding: 0.2rem 0.5rem;
  background-color: #145C9E;
  color: white;
  border-radius: 0.2rem;
  margin-right: 0.5rem;
}

.searching_database {
  padding: 1rem;
}

.no-data-found {
  padding: 1rem;
  background-color: #fee5da;
  border-radius: 0.8rem;
}

.results-count {
  padding: 1rem;
  background-color: #e8f2fc;
}

.sort-icon {
  margin-top: 5px;
}

.actions .title {
  display: flex;
  align-items: center;
  gap: 20px;
}
.actions .title .users-results-count {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  background-color: #feefe9;
}
.actions .title .users-results-count .count {
  background-color: #F87C47;
  color: #ffffff;
  padding: 5px 12px;
  border-radius: 8px;
}
.actions .title .users-results-count span:nth-child(2) {
  text-transform: capitalize;
  font-weight: bold;
  color: #F87C47;
  font-size: 14px;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table thead {
  background-color: whitesmoke;
}
table th {
  padding: 12px;
  text-align: start;
  color: gray;
  text-wrap: nowrap;
}
table .sort-cell {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.8rem;
}
table td {
  padding: 12px;
  text-align: start;
  text-wrap: nowrap;
}
table .tag {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
table .tag .tag-data {
  background-color: #fee5da;
  color: #F87C47;
  padding: 4px;
  border-radius: 4px;
}
table .table-data-list {
  display: flex;
  align-items: center;
  gap: 1rem;
}
table tr {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}
table tr .table-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
}
table tbody tr:hover {
  background-color: #ffece3;
}
table .action-col {
  cursor: pointer;
  text-align: end;
  position: relative;
}
table .action-col .actions {
  position: absolute;
  padding: 32px;
  right: 0;
  bottom: 0;
  display: grid;
  gap: 16px;
  background-color: whitesmoke;
  border-radius: 12px;
  z-index: 2;
  background: #ffffff;
  box-shadow: 8px 8px 36px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
table .action-col .actions .action {
  display: flex;
  align-items: center;
  gap: 12px;
  text-wrap: nowrap;
  font-weight: bold;
}

.follow-up {
  background-color: whitesmoke;
  padding: 6px;
  border-radius: 6px;
  text-align: center;
  height: -moz-fit-content;
  height: fit-content;
}

.in-progress {
  background-color: #FFF1EB;
  color: #F87C47;
}

.Open {
  background-color: rgba(10, 210, 10, 0.16);
  color: rgb(1, 64, 1);
}

.closed {
  background-color: rgba(255, 0, 0, 0.205);
  color: rgb(109, 5, 5);
}

.witness-list {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.witness-list .witness {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  background-color: #bbdaf6;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #60a9eb;
}

.filters {
  position: relative;
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
.filters input {
  flex: 1;
}

.filters_popup {
  position: absolute;
  top: 62px;
  right: 0;
  z-index: 2;
  background-color: white;
  padding: 24px;
  box-shadow: 8px 8px 36px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.filters_popup .close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}
.filters_popup select {
  width: 100%;
}
.filters_popup .filter-range {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: left;
}
.filters_popup .filter-buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.filters_popup .filter-buttons .pop-up-buttons {
  width: 100%;
  display: flex;
  gap: 10px;
}
.filters_popup .filter-buttons .pop-up-buttons button {
  width: 100%;
}

/* DatePickerStyles.css */
.custom-datepicker {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid inherit;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker__header {
  background-color: #F87C47;
  color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #F87C47 !important;
  color: #fff;
}

.react-datepicker__day--today {
  font-weight: bold;
  color: #F87C47;
}

.mobile-table {
  display: none;
}

.no-access-text {
  color: #F87C47;
  font-size: 32px;
  padding: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.status-main-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-main-container .status-wrapper {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.status-main-container .status-wrapper .row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.status-main-container .status-wrapper .row h1 {
  font-size: 32px;
}
.status-main-container .status-wrapper .row .img-container {
  height: 46px;
  width: 109px;
}
.status-main-container .status-wrapper .row .img-container img {
  height: 100%;
  width: 100%;
}
.status-main-container .status-wrapper p {
  text-align: center;
  font-size: 16px;
}
.status-main-container .status-wrapper .links {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.status-main-container .status-wrapper .links .link {
  text-decoration: underline;
  color: #145C9E;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .filters .date-filter-button {
    padding: 12px;
  }
  .filters .date-filter-button span {
    display: none;
  }
  button {
    padding: 14px;
  }
  button span {
    display: none;
  }
  .filters_popup {
    left: 0;
  }
  .mobile-table {
    display: grid;
    height: -moz-fit-content;
    height: fit-content;
    gap: 1rem;
  }
  .mobile-table button {
    width: -moz-fit-content;
    width: fit-content;
    padding: 14px;
  }
  .mobile-table .table-card {
    padding: 1rem;
    border: 1px solid #e3e3e3;
    border-radius: 0.5rem;
    display: grid;
    height: -moz-fit-content;
    height: fit-content;
    gap: 2rem;
  }
  .mobile-table .table-card label {
    color: gray;
  }
  .mobile-table .table-card .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
  .mobile-table .table-card .card-header .id-number {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .mobile-table .table-card .card-header .card-actions {
    display: flex;
    align-items: center;
    color: #145C9E;
  }
  .mobile-table .table-card .card-content-items {
    display: grid;
    height: -moz-fit-content;
    height: fit-content;
    gap: 1rem;
  }
  table {
    display: none;
  }
  table thead {
    background-color: whitesmoke;
  }
  table thead tr th {
    display: none;
  }
  table thead tr {
    display: flex;
    align-items: center;
  }
  table thead tr th:nth-child(1), table thead tr th:nth-child(2), table thead tr th:last-child {
    display: inline-block;
    flex: 1;
  }
  table thead tr th:first-child {
    max-width: 50px;
  }
  table tbody tr {
    display: flex;
    align-items: center;
  }
  table tbody tr td {
    display: none;
  }
  table tbody tr td:nth-child(1), table tbody tr td:nth-child(2), table tbody tr td:last-child {
    display: inline-block;
    flex: 1;
  }
  table tbody tr td:first-child {
    max-width: 50px;
  }
}
.search-input {
  padding: 0 26px;
}
.search-input input {
  border: none;
}

button,
.button {
  cursor: pointer;
  justify-content: center;
}

.divider {
  width: 3px;
  height: 100%;
}

.page-content {
  padding: 12px;
}

.searching-content {
  padding: 24px;
}

.date-filter {
  position: relative;
}
.date-filter .calendars-container {
  position: absolute;
  bottom: -1;
  right: 0;
  padding: 12px;
  border-radius: 6px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}
.date-filter .calendars-container .calendars {
  display: flex;
  align-items: center;
  gap: 24px;
}
.date-filter .calendars-container .calendars .calendar {
  display: grid;
  gap: 12px;
}

.new-item-full-container {
  background-color: white;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83vh;
}

.mark-resolved {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}

.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4196078431);
}
.pop-up .popup-content {
  max-width: 650px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
  padding-bottom: 0;
  position: relative;
  animation: openPopup 0.3s forwards;
  border-left: 1px solid #eaeaea;
  border-radius: 0.8rem;
}
.pop-up .popup-content .close {
  animation: closePopup 0.3s forwards;
}
.pop-up .popup-content .close-popup {
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 999;
}
.pop-up .popup-content .close-popup i {
  font-size: 32px;
}
@keyframes openPopup {
  0% {
    transform: translateX(700px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes closePopup {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(700px);
  }
}

.document-link .row {
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: #fafafa;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
}
.document-link .row .document-icon {
  height: 30px;
  width: 30px;
  color: #145C9E;
}
.document-link .row .col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 960px) {
  .theme-switcher {
    right: unset;
    left: 24px;
    height: 52px;
    width: 52px;
  }
  .theme-switcher .switch-text {
    display: none;
  }
  .theme-switcher .icon {
    height: 32px;
    width: 32px;
  }
  .container {
    padding: 24px;
  }
  .pop-up .popup-content {
    max-width: 100%;
  }
}
input[type=checkbox] {
  width: -moz-fit-content;
  width: fit-content;
}

[data-theme=light] {
  background-color: white;
}
[data-theme=light] .date-filter-button,
[data-theme=light] .calendars-container {
  background-color: #145C9E;
  color: white;
}
[data-theme=light] .pop-up .popup-content {
  background-color: white;
  color: black;
}
[data-theme=light] .title {
  color: #145C9E;
}
[data-theme=light] .theme-switcher {
  background-color: #1E1E1E;
  color: white;
}
[data-theme=light] .primary-button {
  background-color: #F87C47;
  color: white;
}
[data-theme=light] .primary-button:hover {
  background-color: #145C9E;
}
[data-theme=light] .primary-button:disabled {
  background-color: #f98d60;
}
[data-theme=light] .one-bg {
  background-color: rgba(248, 124, 71, 0.1882352941);
  color: #F87C47;
  cursor: auto;
}
[data-theme=light] select {
  border: 1px solid #909090;
}
[data-theme=light] .search-input {
  background-color: white;
}

.new-action-button {
  position: relative;
}

#FormChoicesPopup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.339);
  z-index: 8;
}
#FormChoicesPopup .content {
  position: absolute;
  top: 80px;
  right: calc(10vw + 6px);
  z-index: 10;
  background-color: white;
  z-index: 10;
  max-width: 550px;
  border-radius: 12px;
  padding: 24px;
  color: gray;
}
#FormChoicesPopup .choices {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
#FormChoicesPopup .choices .choice {
  flex: 1 1 200px;
  max-width: 250px;
  background-color: whitesmoke;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  text-wrap: wrap;
  text-align: start;
}
#FormChoicesPopup .choices .choice:hover {
  background-color: #FFF1EB;
  border: 1px solid #F87C47;
  color: #F87C47;
}
#FormChoicesPopup .choices .choice .icon {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 18px;
}

.secondary-button {
  background-color: #145C9E;
  color: white;
}
.secondary-button:hover {
  background-color: #145C9E;
}
.secondary-button:disabled {
  background-color: #1a76cb;
}

.tertiary-button {
  background-color: transparent;
  color: #145C9E;
  border: 1px solid #145C9E;
}
.tertiary-button:hover {
  border: 1px solid #F87C47;
  color: #F87C47;
}

.delete-button {
  background-color: tomato;
  color: white;
}
.delete-button:hover {
  background-color: red;
}

[data-theme=dark] #FormChoicesPopup .content {
  background-color: #2C2C2C;
}
[data-theme=dark] #FormChoicesPopup .choices .choice {
  background-color: rgb(66, 66, 66);
  color: white;
}
[data-theme=dark] #FormChoicesPopup .choices .choice:hover {
  border: 1px solid #F87C47;
  color: #F87C47;
}

.loading-icon {
  animation: animateLoadingIcon 1s linear infinite;
}

@keyframes animateLoadingIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
[data-theme=dark] {
  background-color: #1E1E1E;
  color: white;
}
[data-theme=dark] .date-filter-button,
[data-theme=dark] .calendars-container {
  background-color: #3c3c3c;
  color: whitesmoke;
}
[data-theme=dark] table th {
  background-color: rgb(28, 28, 28);
  color: whitesmoke;
}
[data-theme=dark] table tr:nth-child(even) {
  background-color: #2C2C2C;
}
[data-theme=dark] .divider {
  background-color: #3c3c3c;
}
[data-theme=dark] .pop-up {
  background-color: rgba(0, 0, 0, 0.566);
}
[data-theme=dark] .pop-up .popup-content {
  background-color: #2C2C2C;
}
[data-theme=dark] .title {
  color: white;
}
[data-theme=dark] .theme-switcher {
  background-color: white;
  color: #2C2C2C;
}
[data-theme=dark] .primary-button {
  background-color: white;
  color: #1E1E1E;
}
[data-theme=dark] .primary-button:hover {
  background-color: #F87C47;
  color: white;
}
[data-theme=dark] input,
[data-theme=dark] select {
  background-color: rgb(44, 44, 44);
  border: black;
  color: white;
}
[data-theme=dark] select {
  border: 1px solid whitesmoke;
}
[data-theme=dark] .search-input {
  color: #909090;
}

.loader {
  background-color: whitesmoke;
  animation: shimmer 2s linear infinite;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  background-size: 800px 100px;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.form-complete-message {
  border-radius: 12px;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
}
.form-complete-message button {
  width: 100%;
}
.form-complete-message .message-container {
  background-color: #f7f7f7;
  padding: 48px;
  margin: auto;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
  border-radius: 12px;
  text-align: center;
  justify-items: center;
}
.form-complete-message .message-container .icon {
  background-color: #F87C47;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.form-complete-message .message-container .message {
  color: #898989;
}

.back-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 12px;
}
.back-link .icon {
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border: 1px solid rgb(238, 236, 236);
  border-radius: 4px;
}
.back-link p {
  font-size: 1rem;
}

.files-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.files-list .file-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #fafafa;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.files-list .file-container .image-content {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}
.files-list .file-container .image-content small {
  color: gray;
}
.files-list .file-container .icon {
  height: 2rem;
  width: 2rem;
}
.files-list .file-container .icon img {
  width: 100%;
}

.files-list-small {
  gap: 1rem;
}
.files-list-small .file .icon {
  height: 1rem;
  width: 1rem;
}

.auth-pages {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.auth-pages .form {
  width: 100%;
  max-width: 500px;
  padding: 2rem;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.auth-pages .form input,
.auth-pages .form button,
.auth-pages .form .button {
  width: 100%;
}

.error-message,
.warning-message,
.success-message,
.note-message {
  padding: 1rem;
  animation: messages ease 1s forwards;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.error-message .message,
.warning-message .message,
.success-message .message,
.note-message .message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
}

.error-message {
  background-color: rgba(255, 99, 71, 0.22);
}
.error-message .message-icon {
  color: red;
}

.no-resources-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.no-resources-container .no-resources-wrapper {
  position: relative;
  top: -100px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  max-width: 500px;
}
.no-resources-container .no-resources-wrapper h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #817d7d;
}
.no-resources-container .no-resources-wrapper h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #145C9E;
}
.no-resources-container .no-resources-wrapper p {
  font-size: 18px;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
}
.no-resources-container .no-resources-wrapper .overview-btn {
  background-color: #F87C47;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

.success-message {
  background-color: rgba(85, 255, 201, 0.462745098);
}
.success-message .message-icon {
  color: green;
}

.warning-message {
  background-color: rgb(255, 245, 186);
}
.warning-message .message-icon {
  color: rgb(121, 121, 5);
}

.note-message {
  background-color: #e8f2fc;
}
.note-message .message-icon {
  color: #145C9E;
}

.report-list-with-notes {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}

@keyframes messages {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100%;
  }
}
.tracking-headers {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.tracking-headers .tabs {
  border: 1px solid #f1f1f1;
  padding: 0.5rem;
}
.tracking-headers .tabs .tracking-tab {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
}
.tracking-headers .tabs .active {
  background-color: #fff1eb;
  color: #F87C47;
}

.grid-choices {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.document-history-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.authors-list {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.authors-list > :nth-child(2) {
  position: relative;
  left: -2rem;
}
.authors-list > :nth-child(3) {
  position: relative;
  left: -4rem;
}
.authors-list > :nth-child(4) {
  position: relative;
  left: -6rem;
}
.authors-list > :nth-child(even) {
  background-color: #fee5da;
}
.authors-list:hover > :nth-child(2),
.authors-list:hover > :nth-child(3),
.authors-list:hover > :nth-child(4) {
  left: 0;
}
.authors-list .author {
  transition: all linear 0.3s;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  background-color: #e8f2fc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  border: 1px solid #145C9E;
  color: #145C9E;
}
.authors-list .author .details {
  position: absolute;
  display: none;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  padding: 2rem;
  border-radius: 8px;
  top: 2rem;
  background-color: rgb(245, 245, 245);
  border: 1px solid whitesmoke;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 100;
}
.authors-list .author .details .assignee {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.authors-list .author .details .assignee .name-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.authors-list .author .details .assignee .name-profile .name-initials {
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  background-color: #e8f2fc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.authors-list .author:hover .details {
  display: grid;
}

.roles-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.roles-container .hide-roles {
  position: absolute;
  top: 1rem;
  bottom: unset;
  right: 1rem;
  background-color: #145C9E;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.roles-container .roles {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.roles-container .role {
  background-color: whitesmoke;
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}

@media screen and (max-width: 768px) {
  .form-complete-message {
    width: 100%;
  }
  .back-link p {
    display: none;
  }
}
.checking-permissions {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

@keyframes openFilters {
  from {
    max-height: 0;
  }
  to {
    max-height: 500px;
  }
}
@keyframes closeFilters {
  from {
    max-height: 500px;
  }
  to {
    max-height: 4rem;
  }
}
.filtering {
  animation: filteringAnimation ease-in-out 1s infinite;
}

@keyframes filteringAnimation {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.6;
  }
}
.qr-code {
  max-width: 400px;
}

.mfa-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mfa-page .container {
  max-width: 1280px;
  margin: auto;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mfa-page .container .instructions {
  flex: 1 1 300px;
  max-width: 400px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.mfa-page .container .qr-code {
  flex: 1 1 300px;
}

.qr-code-form {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  padding: 1rem;
  background-color: whitesmoke;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 0.5rem;
}
.qr-code-form .half {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}
.qr-code-form .half input {
  flex: 1 1 200px;
}
.qr-code-form .half button {
  flex: 1 1 120px;
}

.review-popup-content {
  display: grid;
  padding: 2rem;
  border-radius: 1rem;
  gap: 0.5rem;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .mfa-page .container {
    justify-content: center;
    flex-direction: column-reverse;
    padding-top: 3rem 0;
    gap: 1rem;
  }
  .mfa-page .container .qr-code-image,
  .mfa-page .container .qr-code {
    max-height: 200px;
    background-color: whitesmoke;
  }
}
.no-content-found {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}/*# sourceMappingURL=main.css.map */