.first-assaliant {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 12px;
}
.first-assaliant h2 {
  font-size: 17px;
  color: #32343A;
  font-weight: 600;
}
.first-assaliant h4 {
  font-size: 16px;
  color: #32343A;
}

.title-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 13px;
}
.title-fields .individual-ttle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title-fields .individual-ttle h4 {
  font-size: 18px;
}/*# sourceMappingURL=workplace.css.map */