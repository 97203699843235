.page-content {
  display: grid;
  gap: 12px;
  height: -moz-fit-content;
  height: fit-content;
}
.page-content .tabs {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  border-radius: 12px;
}
.page-content .tabs .tab {
  cursor: pointer;
  display: flex;
  padding: 16px;
  gap: 10px;
  align-items: center;
  text-wrap: nowrap;
  border-bottom: 3px solid transparent;
  scrollbar-width: 1px;
}
.page-content .tabs .tab .icon {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 6px;
}
.page-content .tabs .tab .icon i {
  font-size: 12px;
}
.page-content .tabs .tab .counter {
  height: 22px;
  width: 22px;
  background-color: #F87C47;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}
.page-content .tab-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}
.page-content .tab-container .tab-header {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
  padding: 12px;
  border-radius: 12px;
}
.page-content .tab-container .tab-header .title-container-action .title-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.page-content .tab-container .tab-header .title-container-action .title-container p {
  font-weight: bold;
}
.page-content .tab-container .tab-header .filters {
  display: flex;
  justify-content: space-between;
}
.page-content .tab-container .tab-header .filters .filter-buttons {
  display: flex;
  gap: 12px;
}
.page-content .tab-container .grievance-tab-headers {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  padding-top: 40px;
  gap: 50px;
  width: 100% !important;
}
.page-content .tab-container .incident-list {
  padding: 12px;
  border-radius: 12px;
}
.page-content .grievance-tracking-headers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  gap: 20px;
}

.status-choices {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
}
.status-choices .status {
  flex: 49%;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
}

@media screen and (max-width: 760px) {
  .page-content .tab-container .tab-header .filters .filter-buttons {
    flex-wrap: wrap;
  }
}
[data-theme=light] .status-choices .status {
  background-color: #f3f3f3;
}
[data-theme=light] .status-choices .selected {
  background-color: #F87C47;
  color: white;
}
[data-theme=light] .page-content .tabs {
  background-color: white;
}
[data-theme=light] .page-content .tabs .tab {
  border-color: #909090;
}
[data-theme=light] .page-content .tabs .tab .icon {
  border-color: #909090;
}
[data-theme=light] .page-content .tabs .active {
  border-color: #F87C47;
}
[data-theme=light] .page-content .tabs .active p,
[data-theme=light] .page-content .tabs .active i {
  color: #F87C47;
}
[data-theme=light] .page-content .tabs .active .icon {
  border-color: #F87C47;
}
[data-theme=light] .page-content .tab-container .tab-header {
  background-color: white;
}
[data-theme=light] .page-content .tab-container .incident-list {
  background-color: white;
}

[data-theme=dark] .status-choices .status {
  background-color: rgb(65, 65, 65);
}
[data-theme=dark] .status-choices .selected {
  background-color: #F87C47;
  color: white;
}
[data-theme=dark] .page-content .tabs {
  background-color: #2C2C2C;
}
[data-theme=dark] .page-content .tabs .tab {
  border-color: #909090;
}
[data-theme=dark] .page-content .tabs .tab p,
[data-theme=dark] .page-content .tabs .tab i {
  color: #909090;
}
[data-theme=dark] .page-content .tabs .tab .icon {
  border-color: #909090;
}
[data-theme=dark] .page-content .tabs .active {
  border-color: white;
}
[data-theme=dark] .page-content .tabs .active .icon {
  border-color: white;
}
[data-theme=dark] .page-content .tabs .active p,
[data-theme=dark] .page-content .tabs .active i {
  color: white;
}
[data-theme=dark] .page-content .tab-container .tab-header {
  background-color: #2C2C2C;
}
[data-theme=dark] .page-content .tab-container .incident-list {
  background-color: #2C2C2C;
}
[data-theme=dark] .page-content .tab-container .incident-list table tr:nth-child(even) {
  background-color: rgb(31, 31, 31);
}/*# sourceMappingURL=incidents_page.css.map */