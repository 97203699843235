.error-page {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-page .container {
  max-width: 600px;
  margin: auto;
  display: grid;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
}
.error-page .container .branding {
  display: flex;
  align-items: center;
  gap: 18px;
}
.error-page .container .branding .logo {
  height: 40px;
}
.error-page .container .branding .site-title {
  font-size: 22px;
}
.error-page .container .content {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.error-page .container .content .error-title {
  display: flex;
  align-items: center;
  gap: 12px;
}
.error-page .container .content .error-title .error-code {
  color: #F87C47;
  font-size: 32px;
  font-weight: bold;
}
.error-page .container .content .error-title .divider {
  width: 5px;
  height: 50px;
}
.error-page .container .content .error-title .title {
  font-size: 52px;
}
.error-page .container .content .error-details {
  padding: 24px;
  border-radius: 12px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.error-page .container .content .error-details .useful-links {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.error-page .container .content .error-details .useful-links .useful-link {
  padding: 6px 18px;
  border-radius: 12px;
}
.error-page .container .content .error-details .useful-links .useful-link:hover {
  background-color: #145C9E;
  color: white;
}

[data-theme=light] .divider {
  background-color: #f3f3f3;
}
[data-theme=light] .error-details {
  background-color: #f3f3f3;
}
[data-theme=light] .useful-link {
  background-color: #FFF1EB;
  color: #145C9E;
}

[data-theme=dark] .divider {
  background-color: #f3f3f3;
}
[data-theme=dark] .error-details {
  background-color: #2C2C2C;
}
[data-theme=dark] .useful-link {
  background-color: #3c3c3c;
  color: white;
}/*# sourceMappingURL=error_pages.css.map */