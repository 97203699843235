.otpPage {
    height: 100%;
    width: 100%;

    .container {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .verification-container {
            display: flex;

            flex-direction: column;
            gap: 35px;
            text-align: center;

            b {
                font-size: 24px;
            }

            .row {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                a {
                    color: #F87C47;
                    cursor: pointer;
                    font-size: 20px;
                    font-weight: 700;
                    text-decoration: none;
                }
            }

            form {
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 35px;
                align-items: center;

                .inputs {
                    display: flex;
                    gap: 40px;

                    input {
                        font-size: 32px;
                        width: 72px;
                        height: 72px;
                        text-align: center;
                        padding:16px, 26px, 16px, 26px;
                        border-radius: 16px;
                        border: 1px solid #EEEEEE;

                        &:hover {
                            border: 2px solid #F87C47;

                        }
                    }
                }

                button {
                    width: 400px;
                    padding: 20px 26px;
                    background-color: #F87C47;
                    color: #FFFFFF;
                    border-radius: 30px;
                    font-weight: 500;
                    text-align: center;
                    font-size: 18px;
                    border: none;
                    cursor: pointer;

                    &:hover {
                        border: 2px solid #F87C47;
                        color: #F87C47;
                        background-color: #FFFFFF;

                    }
                }
                .last{
                    display: flex;
                    gap: 10px;
                    
                    
                    #timer{
                        font-weight: 700;
                        
                    }
                    #resend{
                
                        font-weight: 700;
                        cursor: pointer;
                        pointer-events: none;
                    }
    

            }




        }
    }
}
}