.page-content {
  padding: 0;
  position: relative;
  height: calc(100vh - 80px);
  overflow: auto;
}

.incident-details-page {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  height: 100%;
}

.incident-details-header {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 12px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  z-index: 2;
}
.incident-details-header .incident-steps-and-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  text-wrap: nowrap;
}
.incident-details-header .incident-steps-and-type .details {
  display: flex;
  align-items: center;
  gap: 12px;
}
.incident-details-header .incident-steps-and-type .details .incident-type {
  display: flex;
  align-items: center;
}
.incident-details-header .incident-steps-and-type .details .incident-type .type {
  padding: 6px;
  border-radius: 12px;
  border: 1px solid #F87C47;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  color: #F87C47;
  margin-left: 12px;
}
.incident-details-header .incident-steps-and-type .facility-header {
  display: flex;
  gap: 30px;
}
.incident-details-header .incident-steps-and-type .facility-header .facility,
.incident-details-header .incident-steps-and-type .facility-header .department {
  display: flex;
  align-items: center;
  gap: 10px;
}
.incident-details-header .incident-steps-and-type .facility-header .facility .facility-name,
.incident-details-header .incident-steps-and-type .facility-header .facility .department-name,
.incident-details-header .incident-steps-and-type .facility-header .department .facility-name,
.incident-details-header .incident-steps-and-type .facility-header .department .department-name {
  display: flex;
  align-items: center;
  color: gray;
  gap: 5px;
}
.incident-details-header .incident-steps-and-type .facility-header .facility > span,
.incident-details-header .incident-steps-and-type .facility-header .department > span {
  font-weight: bold;
}
.incident-details-header .incident-steps-and-type .progress {
  display: flex;
  align-items: center;
  gap: 12px;
  border-left: 2px solid whitesmoke;
  border-right: 2px solid whitesmoke;
  padding: 0 12px;
}
.incident-details-header .incident-steps-and-type .progress .step {
  display: flex;
  align-items: center;
}
.incident-details-header .incident-steps-and-type .progress .step .number {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: 32px;
  width: 32px;
  background-color: whitesmoke;
  border: 1px solid rgb(228, 228, 228);
}
.incident-details-header .incident-steps-and-type .actions {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}
.incident-details-header .incident-steps-and-type .actions button {
  padding: 8px 12px;
}
.incident-details-header .incident-steps-and-type .actions .actions-popup {
  display: none;
  position: absolute;
  top: 52px;
  right: 0;
  background: #ffffff;
  box-shadow: 8px 8px 36px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px;
  height: -moz-fit-content;
  height: fit-content;
  animation: showActionsAnimation linear 0.3s forwards;
}
.incident-details-header .incident-steps-and-type .actions .actions-popup .action {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 600;
}
.incident-details-header .incident-steps-and-type .actions .actions-popup .action:hover {
  background-color: #F87C47;
  color: white;
}
.incident-details-header .incident-steps-and-type .actions .actions-popup .delete:hover {
  background-color: red;
}
.incident-details-header .incident-steps-and-type .show-actions .actions-popup {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
}
@keyframes showActionsAnimation {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.incident-details-header .incident-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.345);
}
.incident-details-header .preview-popup {
  background-color: pink;
  height: 80vh;
  max-width: 700px;
  width: 100%;
  min-width: 300px;
  overflow-y: auto;
  position: relative;
  display: unset;
}

.send-to-department {
  background-color: white;
  width: 600px;
  padding: 24px;
  border-radius: 12px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.6rem;
}
.send-to-department input {
  width: 100%;
}
.send-to-department .loading {
  display: flex;
  align-content: center;
  gap: 12px;
  height: 100px;
  border: 1px solid whitesmoke;
  border-radius: 12px;
  padding: 12px;
}
.send-to-department .popup-header {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.send-to-department .available-departments {
  display: flex;
  align-items: center;
  gap: 24px;
}
.send-to-department .available-departments small {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 3px;
}
.send-to-department .departments {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px;
  border: 1px solid whitesmoke;
  border-radius: 12px;
}
.send-to-department .departments .department {
  cursor: pointer;
  text-wrap: nowrap;
  padding: 12px 12px;
  background-color: whitesmoke;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.send-to-department .comment,
.send-to-department .file {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
  width: 100%;
}

.details {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.details small {
  color: gray;
}
.details .patient-name-type {
  flex: 1 1 250px;
  max-width: 40%;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
  padding: 12px;
  border-bottom: 1px solid whitesmoke;
}
.details .patient-name-type .date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.details .patient-name-type .name-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.details .patient-name-type .name-profile .profile {
  display: flex;
  align-items: center;
  gap: 12px;
}
.details .patient-name-type .name-profile .profile .profile-pic {
  height: 52px;
  width: 52px;
  background-color: #145C9E;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  font-weight: bold;
  color: white;
  font-size: 18px;
}
.details .patient-name-type .name-profile .patient-type {
  background-color: #FFF1EB;
  color: #F87C47;
  padding: 8px 24px;
  border-radius: 4px;
  text-transform: capitalize;
}
.details .patient-name-type .number-mrn,
.details .patient-name-type .address,
.details .patient-name-type .location-contribution-diagnosis,
.details .patient-name-type .patient-status,
.details .patient-name-type .outcomes {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 24px;
  padding: 12px;
  border-bottom: 1px solid whitesmoke;
}
.details .patient-name-type .number-mrn > div,
.details .patient-name-type .address > div,
.details .patient-name-type .location-contribution-diagnosis > div,
.details .patient-name-type .patient-status > div,
.details .patient-name-type .outcomes > div {
  flex: 1 1 150px;
  display: grid;
  gap: 8px;
  height: -moz-fit-content;
  height: fit-content;
}
.details .incident-type-tabs {
  flex: 1 1 250px;
  border-left: 1px solid whitesmoke;
  padding: 20px;
}
.details .incident-type-tabs .tabs {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 6px;
  border: 1px solid whitesmoke;
  width: -moz-fit-content;
  width: fit-content;
}
.details .incident-type-tabs .tabs .tab {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
}
.details .incident-type-tabs .tabs .active {
  background-color: #FFF1EB;
}
.details .incident-type-tabs .tabs-content {
  margin-top: 24px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .fall-incident_type {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .fall-incident_type .data {
  display: flex;
  align-items: center;
  gap: 12px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .fall-incident_type .data > div {
  flex: 1 1 220px;
  display: grid;
  gap: 8px;
  height: -moz-fit-content;
  height: fit-content;
}
.details .incident-type-tabs .tabs-content .incident-type-data .fall-incident_type .status-prior-to-incident .statuses {
  display: flex;
  align-items: stretch;
  gap: 12px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .fall-incident_type .status-prior-to-incident .statuses .status {
  background-color: #FFF1EB;
  padding: 6px;
  border-radius: 6px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .equipment-type-incident {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .equipment-type-incident .equipment-incident-data {
  display: flex;
  align-items: stretch;
  gap: 24px;
  flex-wrap: wrap;
}
.details .incident-type-tabs .tabs-content .incident-type-data .equipment-type-incident .equipment-incident-data > div {
  flex: 1 1 150px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 6px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .treatment-type-incident {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .treatment-type-incident .treatment-incident-data h4 {
  padding: 6px;
  background-color: whitesmoke;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px;
  border-radius: 6px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .other-type-incident {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.details .incident-type-tabs .tabs-content .incident-type-data .other-type-incident .other-incident-data h4 {
  padding: 6px;
  background-color: whitesmoke;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px;
  border-radius: 6px;
}
.details .incident-type-tabs .tabs-content .incident-general-info {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.details .incident-type-tabs .tabs-content .incident-general-info .notification {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.details .incident-type-tabs .tabs-content .incident-general-info .brief-description {
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  padding: 24px 0;
}
.details .incident-type-tabs .tabs-content .incident-general-info .physician-notified,
.details .incident-type-tabs .tabs-content .incident-general-info .family-notified {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 48px;
}
.details .incident-type-tabs .tabs-content .incident-general-info .physician-notified > div,
.details .incident-type-tabs .tabs-content .incident-general-info .family-notified > div {
  flex: 1 1 220px;
  display: grid;
  gap: 8px;
  height: -moz-fit-content;
  height: fit-content;
}
.details .incident-type-tabs .tabs-content .document-history .document-row {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}
.details .incident-type-tabs .tabs-content .document-history .document-row .small-circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #F87C47;
  margin-top: 5px;
}
.details .incident-type-tabs .tabs-content .document-history .document-row .document-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.details .incident-type-tabs .tabs-content .document-history .document-row .document-col .formatted-date {
  font-size: 12px;
  color: #909090;
}
.details .incident-type-tabs .tabs-content .incident-comments {
  display: grid;
  gap: 24px;
  padding: 24px 0;
  position: relative;
}
.details .incident-type-tabs .tabs-content .incident-comments .review {
  display: flex;
  align-items: start;
  gap: 12px;
}
.details .incident-type-tabs .tabs-content .incident-comments .review .profile .profile-pic,
.details .incident-type-tabs .tabs-content .incident-comments .review .profile .profile-place-holder {
  height: 52px;
  width: 52px;
  background-color: rgba(20, 91, 158, 0.1490196078);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  font-weight: bold;
  color: #145C9E;
  font-size: 18px;
}
.details .incident-type-tabs .tabs-content .incident-comments .review .profile .logged-in {
  background-color: #F87C47;
}
.details .incident-type-tabs .tabs-content .incident-comments .review .content {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 6px;
}
.details .incident-type-tabs .tabs-content .incident-comments .review .content .names-title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.details .incident-type-tabs .tabs-content .incident-comments .review .content .names-title .title {
  background-color: rgba(20, 91, 158, 0.1490196078);
  padding: 6px 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.details .incident-type-tabs .tabs-content .incident-comments .review .content .names-title .title small {
  color: #145C9E;
  font-size: 12px;
}
.details .incident-type-tabs .tabs-content .incident-comments .review .content .date-time {
  font-size: 12px;
}
.details .incident-type-tabs .tabs-content .incident-comments .show-review-button {
  position: fixed;
  right: 24px;
  bottom: 24px;
}
.details .incident-type-tabs .tabs-content .incident-comments .review-form-popup {
  position: fixed;
  bottom: 32px;
  right: 24px;
  background-color: white;
  padding: 24px;
}

[data-theme=light] .dashboard-content {
  background-color: white;
  padding: 0;
}

[data-theme=dark] .incident-details-header .progress .step .number {
  background-color: #3c3c3c;
}

@media screen and (max-width: 1280px) {
  .incident-details-header .incident-steps-and-type .progress .step {
    display: none;
  }
  .incident-details-header .incident-steps-and-type .progress .active {
    display: flex;
  }
}
@media screen and (max-width: 840px) {
  .breadcrumbs > * {
    display: none;
  }
  .breadcrumbs > :nth-last-child(1),
  .breadcrumbs :nth-last-child(2),
  .breadcrumbs :nth-last-child(3) {
    display: block;
  }
  .dashboard-container .dash-header .header-actions .new-action-button {
    display: none !important;
  }
  .incident-details-header .incident-steps-and-type .details .incident-type span {
    display: none;
  }
  .incident-details-header .incident-steps-and-type .progress .active {
    display: none;
  }
  .incident-details-header .incident-steps-and-type .progress .current {
    display: flex;
  }
  .incident-details-header .incident-steps-and-type .progress .current .text {
    display: none;
  }
  .incident-details-header .incident-steps-and-type .actions .outline-button {
    display: none;
  }
  .incident-details-header .incident-steps-and-type .actions .primary-button span {
    display: none;
  }
  .details h3,
  .details h4 {
    font-size: 14px;
  }
  .details .patient-name-type {
    flex: 1 1 250px;
    max-width: 100%;
    gap: 6px;
  }
  .details .incident-type-tabs {
    max-width: 360px;
  }
  .details .incident-type-tabs .tabs {
    justify-content: space-between;
    width: unset;
  }
  .details .incident-type-tabs .tabs .tab {
    flex: 1;
    background-color: whitesmoke;
  }
  .details .incident-type-tabs .tabs .active {
    background-color: #FFF1EB;
  }
  .details .incident-type-tabs .tabs-content .incident-type-data .fall-incident_type {
    gap: 12px;
  }
  .details .incident-type-tabs .tabs-content .incident-general-info .notification {
    gap: 12px;
  }
  .details .incident-type-tabs .tabs-content .incident-general-info .brief-description {
    gap: 12px;
  }
  .details .incident-type-tabs .tabs-content .incident-general-info .physician-notified,
  .details .incident-type-tabs .tabs-content .incident-general-info .family-notified {
    gap: 12px;
  }
  .details .incident-type-tabs .tabs-content .review-form-popup {
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .complain-details-popup .complaint-details .items-group {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=generalIncidentDetailsPage.css.map */