.number-mrn,
.address,
.location-contribution-diagnosis,
.patient-status,
.outcomes {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 24px;
  padding: 12px;
  border-bottom: 1px solid whitesmoke;
}
.number-mrn > div,
.address > div,
.location-contribution-diagnosis > div,
.patient-status > div,
.outcomes > div {
  flex: 1 1 150px;
  display: grid;
  gap: 8px;
  height: -moz-fit-content;
  height: fit-content;
}

.number-mrn {
  align-items: start !important;
}/*# sourceMappingURL=medication_details.css.map */