@import "../../variables.scss";

.dashboard-container {
  display: flex;
  align-items: start;
  width: 100vw;

  .side-bar {
    width: 280px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 0;
    bottom: 0;

    .links {
      display: grid;
      gap: 12px;
      height: fit-content;

      .branding {
        height: 80px;
        display: flex;
        padding: 24px;

        .logo {
          display: flex;
          align-items: center;
          gap: 6px;

          .site-title {
            color: white;
            font-size: 16px;
          }

          img {
            width: 50px;
          }
        }
      }

      .menu-links {
        display: grid;
        height: fit-content;
        gap: 12px;

        .link {
          padding: 12px 24px;
          display: flex;
          align-items: center;
          gap: 6px;
          text-wrap: nowrap;

          .icon {
            height: 28px;
            width: 28px;
            border-radius: 6px;
            border: 1px solid white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .menu-actions {
      padding: 24px;
      display: grid;
      height: fit-content;
      gap: 12px;

      .link {
        display: flex;
        gap: 12px;
      }
    }
  }

  .dashboard-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .dash-header {
      position: relative;
      display: flex;
      align-items: center;
      height: 80px;
      justify-content: space-between;
      padding: 12px;
      z-index: 100;
      border-bottom: 1px solid whitesmoke;

      .header-actions {
        // position: relative;
        display: flex;
        align-items: center;
        gap: 12px;

        .notification,
        .profile {
          height: 52px;
          width: 52px;
          border: 1px solid $primary-color;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 0.3rem;
          cursor: pointer;

          .dot {
            position: absolute;
            top: 5px;
            left: 24px;
            padding: 3px;
            display: flex;
            min-width: 17px;
            min-height: 17px;
            justify-content: center;
            align-items: center;
            background-color: $secondary-color;
            border-radius: 10px;
            z-index: 3;

            span {
              font-size: 10px;
              font-weight: 800;
              color: white;
            }
          }

          // @keyframes shake {
          //   0% {
          //     transform: rotate(0deg);
          //   }
          //   20% {
          //     transform: rotate(-15deg);
          //   }
          //   40% {
          //     transform: rotate(10deg);
          //   }
          //   60% {
          //     transform: rotate(-10deg);
          //   }
          //   80% {
          //     transform: rotate(5deg);
          //   }
          //   100% {
          //     transform: rotate(0deg);
          //   }
          // }

          .shake {
            display: inline-block;
            // animation: shake 1s ease-in-out infinite;
            transform-origin: top center;
            color: $secondary-color;
          }
        }

        .profile {
          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}

// Theming

[data-theme="light"] {
  .side-bar {
    background-color: $primary-color;
    color: white;

    .links {
      .menu-links {
        .active {
          background-color: $secondary-color;
        }
      }

      .branding {
        background-color: #0f589d;
      }
    }
  }

  .dashboard-content {
    background-color: $gray-color-shade;

    .dash-header {
      background-color: white;

      .mobile-menu-button {
        border-color: $gray-color-shade;

        .bar {
          background-color: $dark-body-background-color;
        }
      }
    }
  }
}

[data-theme="dark"] {
  .side-bar {
    background-color: rgb(19, 19, 19);

    .links {
      .menu-links {
        .active {
          background-color: $dark-gray-color-shade;
        }
      }

      .branding {
        background-color: rgb(13, 13, 13);
      }
    }
  }

  .dashboard-content {
    background-color: $dark-body-background-color;

    .dash-header {
      background-color: $dark-gray-color-shade;

      .mobile-menu-button {
        border-color: white;

        .bar {
          background-color: white;
        }
      }
    }
  }
}

small {
  color: gray;
}

.profile-card {
  position: absolute;
  top: 4.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  right: 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  display: grid;
  height: fit-content;
  gap: 1rem;

  .profile-card-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: #f9f9f9;
    padding: 0.5rem 1rem;
    text-wrap: nowrap;
    border-radius: 0.5rem;
  }

  .links {
    display: grid;
    height: fit-content;
    gap: 1.3rem;

    .link {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 500;
      cursor: pointer;
    }

    .special {
      color: $secondary-color;
    }
  }

  .divider {
    border: 1px solid #f9f9f9;
    width: 100%;
  }

  .switch-accounts {
    display: grid;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 0.5rem;
    gap: 1rem;
    max-height: 3.5rem;
    width: 260px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    .current-account {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 230px;

      .link {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .accounts-list {
      display: grid;
      gap: 0.5rem;
      top: 3rem;

      .account {
        background-color: lighten($color: $primary-color, $amount: 60);
        padding: 0.5rem;
        border-radius: 0.5rem;
        cursor: pointer;
        color: $primary-color;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .account-icon {
          height: 28px;
          width: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $primary-color;
          color: white;
          border-radius: 0.2rem;
        }
      }
    }

    .icon {
      transition: all 0.5s ease-in-out;
    }
  }

  .show {
    // display: grid;
    max-height: 500px;
    // animation: switchAccounts linear 1s forwards;

    .icon {
      transform: rotate(90deg);
    }
  }
}

.notifications-container {
  position: absolute;
  top: 4.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  right: 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  display: grid;
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
  gap: 1rem;
  width: 100%;
  max-width: 400px;

  .notifications-tabs {
    display: flex;
    align-items: center;

    .tab {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border-bottom: 2px solid #f7f7f7;
      padding: 1rem;
      cursor: pointer;
    }

    .active {
      border-bottom: 2px solid $secondary-color;
    }
  }

  .no-notifications {
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .tab-content {
    .notification-list {
      display: grid;
      height: fit-content;
      gap: 1rem;

      .notification-container {
        cursor: pointer;
        background-color: #f9f9f9;
        border-radius: 0.5rem;
        padding: 0.5rem;
        display: grid;
        height: fit-content;
        gap: 0.2rem;
        position: relative;
        transition: 0.5s ease;

        &:hover {
          background-color: #ededed;
        }
        .delete-icon {
          transition: 0.5s ease;
          &:hover {
            color: $secondary-color;
          }
        }

        .notification-content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .time-status {
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        .status {
          .read,
          .unread {
            display: flex;
            align-items: center;
            gap: 0.1rem;
          }

          .read {
            color: $primary-color;
          }

          .unread {
            color: $secondary-color;
          }
        }
      }

      .action-icon {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        cursor: pointer;
        color: gray;
        display: flex;
        align-items: center;
        background-color: transparent;

        .view-details {
          // display: none;
          text-wrap: nowrap;
          max-width: 0;
          overflow: hidden;
          transition: all 1s ease;
          opacity: 0;
        }
      }
    }
  }

  .not-icon {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lighten($color: $primary-color, $amount: 60);
    border-radius: 50%;
  }

  .warning {
    background-color: #fffaee;
    color: #ffb708;
  }

  .error {
    background-color: lighten($color: red, $amount: 60);
    color: red;
  }

  .notifications-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .action {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .view-all {
      color: $secondary-color;
    }
  }
}

.welcome-page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;

  .quick-actions {
    margin: auto;
    border: 1px solid #f9f3f3;
    padding: 2rem;
    border-radius: 1rem;
    max-width: 800px;
    width: 100%;
    display: grid;
    height: fit-content;
    gap: 1rem;

    .card {
      .actions-grid {
        display: flex;
        align-items: stretch;
        gap: 1rem;
        flex-wrap: wrap;

        .action {
          cursor: pointer;
          flex: 1 1 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          border: 1px solid #f9f3f3;
          padding: 1rem;
          background-color: lighten($color: $secondary-color, $amount: 32);
          border-radius: 0.5rem;
          transition: 0.3s linear;

          > :first-child {
            color: $secondary-color;
          }

          &:hover {
            background-color: $secondary-color;
            color: white;

            > :first-child {
              color: white;
            }
          }

          span {
            text-wrap: nowrap;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .dashboard-container {
    .side-bar {
      width: fit-content;

      .links {
        justify-items: center;

        .branding {
          .logo {
            .site-title {
              display: none;
            }
          }
        }

        .menu-links {
          justify-items: center;

          .link {
            .link-text {
              display: none;
            }
          }
        }
      }

      .menu-actions {
        justify-items: center;

        .link {
          .link-text {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .dashboard-container {
    .side-bar {
      position: absolute;
      display: none;
      width: 100%;
      justify-content: start;
      gap: 50px;
      padding-top: 50px;

      .links {
        .branding {
          display: none;
        }

        .menu-links {
          justify-self: start;
          width: 100%;

          .link {
            width: 100%;

            .link-text {
              display: block;
            }
          }
        }
      }

      .menu-actions {
        justify-self: start;
        width: 100%;

        .link {
          width: 100%;

          .link-text {
            display: block;
          }
        }
      }
    }

    .is-menu-open {
      z-index: 2000;
      display: flex;
      top: 80px;
    }

    .dash-header {
      .mobile-menu-button {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 52px;
        height: 52px;
        border: 1px solid transparent;
        padding: 8px;
        border-radius: 12px;

        .bar {
          width: 100%px;
          height: 8px;
          background-color: transparent;
          border-radius: 2px;
          transition: transform 0.3s ease;
        }

        .middle {
          width: 80%;
        }
      }

      .open {
        justify-content: center;

        .middle {
          display: none;
        }

        .first {
          transform: rotate(45deg) translate(3px, 3px);
        }

        .last {
          transform: rotate(-45deg) translate(2px, -2px);
        }
      }

      .header-actions {
        flex-direction: row-reverse;

        .new-action-button {
          position: fixed;
          bottom: 24px;
          right: 24px;
          padding: 0;
          height: 52px;
          width: 52px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            display: none;
          }
        }
      }
    }
  }
}

.facility-card {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: lighten($color: $primary-color, $amount: 60);
  // width: fit-content;
  padding: 1rem;
  border: 1px solid lighten($color: $primary-color, $amount: 30);
  border-radius: 0.8rem;

  .facility {
    color: gray;
  }
}

.padding {
  display: none;
  border: 1px solid lighten($color: $primary-color, $amount: 30);
  border-radius: 0.8rem;
  margin: 1rem;
  background-color: lighten($color: $primary-color, $amount: 60);
}
