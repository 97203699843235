body {
  width: 100%;
  position: relative;
}

.overview-content {
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.overview-content .numbers {
  padding: 12px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 12px;
  background-color: white;
  border-radius: 12px;
}
.overview-content .numbers .number {
  flex: 1 1 200px;
  background-color: whitesmoke;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  min-width: unset;
}
.overview-content .numbers .number .icon {
  color: #F87C47;
  background-color: #FFF1EB;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overview-content .numbers .number .number-title {
  font-size: 14px;
}
.overview-content .numbers .number .content {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 3px;
}
.overview-content .numbers .number .content .count {
  font-size: 16px;
  font-weight: bold;
}
.overview-content .charts-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.overview-content .charts-container .charts-row {
  display: grid;
  grid-template-columns: 1fr;
}
.overview-content .charts-container .charts-row .general-incident-progress {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100% !important;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 6px;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col > span {
  font-size: 12px;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col .progress-row {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col .progress-row .progress-bar {
  width: 100%;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col .progress-row .progress-bar progress {
  border-radius: 7px;
  width: 100%;
  height: 15px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col .progress-row .progress-bar progress::-webkit-progress-bar {
  background-color: #ebf5ff;
  border-radius: 5px;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col .progress-row .progress-bar progress::-webkit-progress-value {
  background-color: #145C9E;
  border-radius: 5px;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col .progress-row .progress-bar progress::-moz-progress-bar {
  background-color: #ebf5ff;
  border-radius: 5px;
}
.overview-content .charts-container .charts-row .general-incident-progress .progress-container .progress-col .progress-row .progress-info {
  color: #626262;
  font-size: 12px;
}
.overview-content .charts-container .charts-row .pie-chart-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.overview-content .charts-container .charts-row .pie-chart-container .pie-chart {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overview-content .charts-container .charts-row .pie-chart-container .pie-chart .label-center {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
.overview-content .charts-container .charts-row .pie-chart-container .pie-chart .label-center > span {
  font-size: 14px;
  color: #5f5f5f;
}
.overview-content .charts-container .charts-row .pie-chart-container .pie-chart .label-center h4 {
  font-size: 26px;
}
.overview-content .charts-container .charts-row .pie-chart-container .edit-label-center {
  margin-top: -20px;
}
.overview-content .charts-container .charts-row .pie-chart-container .stats {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.overview-content .charts-container .charts-row .pie-chart-container .stats .stat {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}
.overview-content .charts-container .charts-row .pie-chart-container .stats .stat .label {
  color: #626262;
  width: -moz-fit-content;
  width: fit-content;
}
.overview-content .charts-container .charts-row .pie-chart-container .stats .stat .value {
  font-weight: bold;
}
.overview-content .charts-container .charts-row .pie-chart-container .edit-medication-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -40px;
  font-size: 12px;
  padding-inline: 20px;
}
.overview-content .charts-container .charts-row .pie-chart-container .edit-medication-stats .stat {
  width: 100%;
}
.overview-content .charts-container .charts-row .pie-chart-container .edit-medication-stats .stat .circle-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.overview-content .charts-container .charts-row .pie-chart-container .edit-stats {
  margin-top: -60px;
}
.overview-content .charts-container .charts-row .pie-chart-container .view-details-orange {
  background-color: #F87C47;
  color: white;
  padding: 10px 22px;
  border-radius: 8px;
  font-weight: 700;
  width: -moz-fit-content;
  width: fit-content;
}
.overview-content .charts-container .charts-row .edit-pie-chart-container {
  gap: 0;
}
.overview-content .charts-container .charts-row .edit-pie-chart-container .pie-chart {
  margin-top: -60px;
}
.overview-content .charts-container .charts-row .workplace-row {
  display: flex;
  gap: 20px;
  align-items: center;
}
.overview-content .charts-container .charts-row .workplace-row span {
  color: #626262;
}
.overview-content .charts-container .charts-row .workplace-row h4 {
  font-size: 20px;
}
.overview-content .charts-container .charts-row .incident-blocks {
  display: flex;
  gap: 5px;
}
.overview-content .charts-container .charts-row .labels {
  display: grid;
  gap: 20px;
  margin-top: 20px;
  font-size: 12px;
  grid-template-columns: 1fr 1fr;
}
.overview-content .charts-container .charts-row .labels .label-col {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.overview-content .charts-container .charts-row .labels .label-col .label {
  display: flex;
  align-items: center;
  gap: 5px;
}
.overview-content .charts-container .charts-row .labels .label-col .label span {
  color: #626262;
}
.overview-content .charts-container .graph-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.overview-content .charts-container .graph-row .complaints {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 6px;
}
.overview-content .charts-container .graph-row .complaints .complaints-table th,
.overview-content .charts-container .graph-row .complaints .complaints-table td {
  text-align: start;
}
.overview-content .charts-container .graph-row .complaints .complaints-table th .table-actions,
.overview-content .charts-container .graph-row .complaints .complaints-table td .table-actions {
  justify-content: flex-start;
}
.overview-content .charts-container .graph {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  border-radius: 6px;
}
.overview-content .charts-container .graph .header-row {
  padding: 20px;
  padding-bottom: 0;
}
.overview-content .charts-container .graph .header-row .legends {
  display: flex;
  gap: 20px;
}
.overview-content .charts-container .graph .header-row .legends .legend {
  display: flex;
  align-items: center;
  gap: 5px;
}
.overview-content .charts-container .graph .main-graph {
  width: 100%;
}

.grievance-graph {
  padding: 0 !important;
  background-color: white;
  border-radius: 6px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
.header-row .view-details {
  color: #F87C47;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}

.edit-header-row {
  padding: 20px;
  padding-bottom: 0;
}

.lost-found {
  justify-content: center;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  min-width: 10px;
}

.block {
  border-radius: 6px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;
  font-weight: 800;
}

.blue {
  background-color: #145C9E;
  color: white;
}

.orange {
  background-color: #F87C47;
  color: white;
}

.green {
  background-color: #208781;
  color: white;
}

.light-blue {
  background-color: #bbddfc;
  color: #145C9E;
}

.light-orange {
  background-color: #ffd2bf;
  color: #145C9E;
}

.light-green {
  background-color: #9fe4e0;
}

.overview-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  cursor: pointer;
}
.overview-filters .filter {
  padding: 0.8rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  min-width: 200px;
  max-width: 400px;
  position: relative;
}
.overview-filters .filter .filters-options {
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 3rem;
  z-index: 2;
  background-color: white;
  padding: 1rem 0;
  border-radius: 0.5rem;
  left: 0;
  right: 0;
  display: none;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: hidden;
  box-shadow: 2px 4px 31px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.overview-filters .filter .filters-options .filter-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding: 0.8rem 1rem;
  text-wrap: nowrap;
  font-size: 12px;
  transition: background-color 0.2s ease-in-out;
}
.overview-filters .filter .filters-options .filter-option::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: whitesmoke;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.overview-filters .filter .filters-options .filter-option:hover {
  background-color: #145C9E;
  color: white;
}
.overview-filters .open {
  border-radius: 0.5rem 0.5rem 0 0;
}
.overview-filters .open .filters-options {
  border-radius: 0 0 0.5rem 0.5rem;
  display: grid;
  animation: openFilters 0.3s ease-in-out forwards;
}
.overview-filters .close {
  animation: closeFilters 0.3s ease-in-out forwards;
}
.overview-filters .date-range {
  padding: 1rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
  border: 1px solid whitesmoke;
}

.overview-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  cursor: pointer;
}
.overview-filters .filter {
  padding: 0.8rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  min-width: 200px;
  max-width: 400px;
  position: relative;
}
.overview-filters .filter .filters-options {
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 3rem;
  z-index: 2;
  background-color: white;
  padding: 1rem 0;
  border-radius: 0.5rem;
  left: 0;
  right: 0;
  display: none;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: hidden;
  box-shadow: 2px 4px 31px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.overview-filters .filter .filters-options .filter-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding: 0.8rem 1rem;
  text-wrap: nowrap;
  font-size: 12px;
  transition: background-color 0.2s ease-in-out;
}
.overview-filters .filter .filters-options .filter-option::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: whitesmoke;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.overview-filters .filter .filters-options .filter-option:hover {
  background-color: #145C9E;
  color: white;
}
.overview-filters .open {
  border-radius: 0.5rem 0.5rem 0 0;
}
.overview-filters .open .filters-options {
  border-radius: 0 0 0.5rem 0.5rem;
  display: grid;
  animation: openFilters 0.3s ease-in-out forwards;
}
.overview-filters .close {
  animation: closeFilters 0.3s ease-in-out forwards;
}
.overview-filters .date-range {
  padding: 1rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
  border: 1px solid whitesmoke;
}

.swiper {
  width: 100% !important;
  height: 100%;
  padding-bottom: 30px !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 20px;
  padding-block: 10px;
  color: #145C9E;
  box-shadow: 0px 14px 31px -6px rgba(0, 0, 0, 0.196);
  font-size: 30px !important;
}

.swiper-button-next {
  right: 30px;
}

.swiper-button-prev {
  left: 30px;
}

.swiper-button-disabled {
  display: none;
}

.swiper-pagination {
  bottom: 5px !important;
}

:root {
  --swiper-theme-color: #f87c47;
}

.main-graph {
  height: 400px !important;
}

@media screen and (min-width: 1540px) {
  .overview-content .numbers .number {
    min-width: 300px;
  }
}
@media screen and (max-width: 900px) {
  .workplace-chart {
    grid-area: area1;
  }
  .overview-content .charts-container .graph-row {
    grid-template-columns: repeat(1, 1fr);
  }
  .overview-content .charts-container .graph-row .complaints-table {
    display: none;
  }
  .overview-content .mobile-table {
    display: grid;
    height: -moz-fit-content;
    height: fit-content;
    gap: 1rem;
  }
  .overview-content .mobile-table .table-card {
    padding: 1rem;
    border: 1px solid #e3e3e3;
    border-radius: 0.5rem;
    display: grid;
    height: -moz-fit-content;
    height: fit-content;
    gap: 2rem;
  }
  .overview-content .mobile-table .table-card label {
    color: gray;
  }
  .overview-content .mobile-table .table-card .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
  .overview-content .mobile-table .table-card .card-header .id-number {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .overview-content .mobile-table .table-card .card-header .card-actions {
    display: flex;
    align-items: center;
    color: #145C9E;
  }
  .overview-content .mobile-table .table-card .card-content-items {
    display: grid;
    height: -moz-fit-content;
    height: fit-content;
    gap: 1rem;
  }
  .overview-filters {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .overview-content .charts-container {
    gap: 30px;
  }
  .swiper {
    width: 100% !important;
    height: 100%;
    padding-bottom: 70px !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    bottom: -50px !important;
    background-color: orange !important;
    top: 87%;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    box-shadow: 0px 14px 31px -8px rgba(0, 0, 0, 0.196);
  }
  .swiper-button-next {
    right: 15px;
  }
  .swiper-button-prev {
    left: 15px;
  }
  .swiper-pagination {
    bottom: 20px !important;
  }
}/*# sourceMappingURL=overview.css.map */