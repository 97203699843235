.no-tickets {
    height: 100%;
    width: 100%;
    // background-color: rebeccapurple;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    text-align: center;

    p {
        max-width: 400px;
    }
}

.tickets-page {
    .table-filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        .search {
            flex: 1;
        }
    }
}

.mobile-tickets {
    display: none;
}

.filter-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 1080px) {

    .table-filters {
        .hide {
            .new {
                display: none;
            }
        }

        button {
            padding: 14px;

            span {
                display: none;
            }
        }

        .filter-actions {
            display: flex;
            align-items: center;
            gap: 1rem;
        }
    }

    .table-tickets {
        display: none;
    }

    .mobile-tickets {
        display: grid;
        gap: 1rem;

        button {
            padding: 14px;

        }

        .tickets {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;

            .ticket {
                flex: 1 1 300px;
                display: grid;
                gap: 1rem;
                border: 1px solid #F5F5F5;
                border-radius: 0.8rem;
                padding: 1rem;


                small {
                    color: gray;
                }

                .number-priority {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .title-date-actions {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .ticket-actions {
                        display: flex;
                        gap: 1rem;
                    }
                }

                .description {
                    display: none;
                }

                &:hover {
                    .description {
                        display: block;
                    }
                }
            }
        }

    }

    .mobile-select-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}