@import '../../variables.scss';

.login-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;

    .branding {
        .logo {
            max-width: 80px;
        }
    }

    .login-form,
    .login-img {
        flex: 1 1 400px;
    }

    .login-img {
        width: 100%;
        max-width: 600px;
        object-fit: cover;
        border-radius: 12px;
    }

    .login-form {
        max-width: 600px;
        padding: 48px;
        display: grid;
        height: fit-content;
        gap: 24px;

        .entities {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            .entity {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                flex-basis: 150px;
                height: 92px;
                font-weight: bold;

                border-radius: 6px;
            }

        }
    }

    .login-email-password {
        display: flex;
        flex-direction: column;
        gap: 12px;

        form {
            padding: 0;

            .input-with-icon {
                position: relative;
                display: flex;
                align-items: center;

                .icon {
                    color: rgba(183, 183, 183, 1);
                    font-size: 20px;
                    position: absolute;
                    left: 1rem;
                }

                input {
                    // border: none;
                    border-radius: 8px;
                    border: 1px solid rgba(183, 183, 183, 1);
                    color: black;
                    font-size: 16px;
                    padding: 12px 24px;
                    padding-left: 3rem;
                }

            }

            .remember-forget {
                display: flex;
                justify-content: space-between;

                label {
                    color: black;
                }

                a {
                    text-decoration: none;
                    color: rgba(248, 124, 71, 1);
                    font-size: 16px;
                }

                .remember {
                    display: flex;
                    gap: 4px;
                    //                     input[type="checkbox"] {
                    //                         background-color: red !important;
                    //                        border:1px solid red;
                    // color: red;
                    //                     }

                    input {
                        width: fit-content;

                        border: 1px solid rgba(248, 124, 71, 1);
                    }
                }
            }

            button {
                padding: 12px 24px;
            }

        }
    }

}

.remember-forget {
    cursor: pointer;
}

@media screen and (max-width: 860px) {
    .login-page {
        .login-img {
            display: none;
        }

        .login-form {
            width: 100%;
            margin: auto;
            padding: 24px;

            .entities {
                .entity {
                    flex-basis: 120px;
                }
            }
        }
    }
}

[data-theme="light"] {
    .login-page {
        .login-form {
            .entities {
                .entity {
                    background-color: whitesmoke;
                    color: $primary-color;
                }

                .selected {
                    border: 1px solid $secondary-color;
                    color: $secondary-color;
                }
            }
        }
    }
}

[data-theme="dark"] {
    .login-page {
        .login-form {
            .entities {
                .entity {
                    background-color: $dark-gray-color-shade;
                    color: white;
                }

                .selected {
                    border: 1px solid white;
                    color: white;
                }
            }
        }
    }
}