.flex-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  h3,
  small {
    flex-grow: 1;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;

  h3,
  small {
    flex-grow: 1;
  }
}

.filters-popup {
  height: 300px;
}

.custom-select-input {
  width: 100%;
}
