.restPassword{
    display: flex;

    .img{
        width: 50%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        p {
            font-style: poppins;


        }

        form{
            max-width: 400px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

           a{
            
                width: 100%;
                padding: 20px 0px;
                background-color: #F87C47;
                color: #FFFFFF;
                border-radius: 30px;
                text-decoration: none;
                font-weight: 500;
                text-align: center;
                font-size: 18px;
                cursor: pointer;


          

            &:hover{
                border: 2px solid #F87C47 ;
                color: #F87C47 ;
                background-color:  #FFFFFF;
               

            }
           }

            button{
                width: 100%;
                padding: 20px 30px;
                border: 2px solid #F87C47 ;
                color: #F87C47 ;
                background-color:  #FFFFFF;
                border-radius: 30px;
                font-weight: 500;
                text-align: center;
                font-size: 18px;
                cursor: pointer;
                &:hover{
                    border: 2px solid #F87C47 ;
                    color: #F87C47 ;
                    background-color:  #FFFFFF;
    
                }
            }
        }
    }
}

