.support-form-popup {
    position: fixed;
    left: 1.4rem;
    bottom: 1.4rem;
    z-index: 100;
    display: flex;
    background-color: white;
    padding: 1.5rem;
    // padding-top: 3.5rem;
    border: 2px solid lighten($color: #145C9E, $amount: 50);
    border-radius: 0.5rem;
    color: rgb(72, 72, 72);
    width: 600px;
    z-index: 101;
    box-shadow: 5px 6px 28px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    animation: formHeight linear 0.5s forwards;
    transition: all linear 0.5s forwards;


    .close-icon {
        position: absolute;
        top: 2rem;
        right: 1.5rem;
        cursor: pointer;
    }

    .notes {
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon {
            height: 2.5rem;
            width: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid lighten($color: #145C9E, $amount: 30);
            border-radius: 0.5rem;
            color: #145C9E;
        }
    }

    .subjects {
        display: flex;
        align-items: stretch;
        gap: 1rem;
        flex-wrap: wrap;



        .subject {
            flex: 1 1 200px;
            padding: 1rem;
            background-color: whitesmoke;
            border-radius: 0.5rem;
        }
    }

    .support-form {
        display: grid;
        height: fit-content;
        gap: 2rem;
        width: 100%;
    }

    .selected-subject {
        display: grid;
        gap: 1rem;
        height: fit-content;

        .subject {
            padding: 1rem;
            background-color: #145C9E;
            border-radius: 0.5rem;
            color: white;
        }

        .explanation {
            background-color: whitesmoke;
            padding: 1rem;
            border-radius: 0.5rem;
        }
    }

    form {
        padding: 0;
    }

    .priorities {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

        .priority {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            // background-color: red;
        }
    }

}

.success-container {
    height: 50vh;
    background-color: #F7FBFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;

    .icon {
        background-color: #FAF4F1;
        padding: 2rem;
        color: #F87C47;
        border-radius: 1rem;
    }

    .title {
        font-weight: bold;
        font-size: 1.5rem;
    }
}

.open-support-link {
    position: fixed;
    bottom: 1.5rem;
    left: 1.5rem;
    z-index: 100;
    color: white;
    background-color: #145C9E;
    border: 1px solid white;
    width: 10rem;
    cursor: pointer;
}

@media screen and (max-width: 1080px) {
    .open-support-link {
        width: 3.5rem;
        left: 1.5rem;
        bottom: 1.5rem;

        .link-text {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .support-form-popup {
        // width: 90vw;
        width: unset;
        left: 1.4rem;
        right: 1.4rem;
        bottom: 1.4rem;

    }

    .success-container {
        .icon {
            padding: 1rem;
        }
    }

}

@keyframes formHeight {
    from {
        max-height: 0;
    }

    to {
        max-height: 90vh;
    }
}