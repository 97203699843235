.first-assaliant{
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 12px;
  h2{
    font-size: 17px;
    color: #32343A;
    font-weight: 600;
  }
  h4{
    font-size: 16px;
    color: #32343A;
  }
}


.title-fields{
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 13px;

   .individual-ttle{
     display: flex;
     flex-direction: column;
     gap: 10px;
     h4{
      font-size: 18px;
     }
   }
}