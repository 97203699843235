@import '../variables.scss';

.forms-container{
    .img-text{
        background-color:#F7F7F7;
        border-radius: 12px;
        padding: 24px;
        width: 500px;
        height: 380px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        h2{
            font-weight: bolder;
            
        }
        p{
            color: $gray-color;
            font-size: 16px;
            text-align: center;
        }
        img{
            width: fit-content;
           
        }
    }
}