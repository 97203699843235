@import "../../variables.scss";

.page-content {
  display: grid;
  gap: 12px;
  height: fit-content;

  .tabs {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    border-radius: 12px;

    .tab {
      cursor: pointer;
      display: flex;
      padding: 16px;
      gap: 10px;
      align-items: center;
      text-wrap: nowrap;
      border-bottom: 3px solid transparent;
      scrollbar-width: 1px;

      .icon {
        height: 26px;
        width: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid transparent;
        border-radius: 6px;

        i {
          font-size: 12px;
        }
      }

      .counter {
        height: 22px;
        width: 22px;
        background-color: $secondary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        border-radius: 3px;
      }
    }
  }

  .tab-container {
    display: grid;
    height: fit-content;
    gap: 12px;

    .tab-header {
      display: grid;
      height: fit-content;
      gap: 24px;
      padding: 12px;
      border-radius: 12px;

      .title-container-action {
        .title-container {
          display: flex;
          flex-direction: column;
          gap: 8px;

          p {
            font-weight: bold;
          }
        }
      }

      .filters {
        display: flex;
        justify-content: space-between;

        .filter-buttons {
          display: flex;
          gap: 12px;
        }
      }
    }
    .grievance-tab-headers {
      display: flex;
      justify-content: space-between;
      padding-right: 15px;
      padding-top: 40px;
      gap: 50px;
      width: 100% !important;
    }

    .incident-list {
      padding: 12px;
      border-radius: 12px;
    }
  }
  .grievance-tracking-headers {
    display: flex;

    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
}

.status-choices {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;

  .status {
    flex: 49%;
    padding: 12px;
    border-radius: 12px;
    cursor: pointer;
  }
}

@media screen and (max-width: 760px) {
  .page-content {
    .tab-container {
      .tab-header {
        .filters {
          .filter-buttons {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

[data-theme="light"] {
  .status-choices {
    .status {
      background-color: $gray-color-shade;
    }

    .selected {
      background-color: $secondary-color;
      color: white;
    }
  }

  .page-content {
    .tabs {
      background-color: white;

      .tab {
        border-color: $gray-color;

        .icon {
          border-color: $gray-color;
        }
      }

      .active {
        border-color: $secondary-color;

        p,
        i {
          color: $secondary-color;
        }

        .icon {
          border-color: $secondary-color;
        }
      }
    }

    .tab-container {
      .tab-header {
        background-color: white;
      }

      .incident-list {
        background-color: white;
      }
    }
  }
}

[data-theme="dark"] {
  .status-choices {
    .status {
      background-color: rgb(65, 65, 65);
    }

    .selected {
      background-color: $secondary-color;
      color: white;
    }
  }

  .page-content {
    .tabs {
      background-color: $dark-gray-color-shade;

      .tab {
        border-color: $gray-color;

        p,
        i {
          color: $gray-color;
        }

        .icon {
          border-color: $gray-color;
        }
      }

      .active {
        border-color: white;

        .icon {
          border-color: white;
        }

        p,
        i {
          color: white;
        }
      }
    }

    .tab-container {
      .tab-header {
        background-color: $dark-gray-color-shade;
      }

      .incident-list {
        background-color: $dark-gray-color-shade;

        table {
          tr:nth-child(even) {
            background-color: rgb(31, 31, 31);
          }
        }
      }
    }
  }
}
